// Import the functions you need from the SDKs you need
import  {initializeApp}  from "firebase/app";
import  {getStorage}  from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDS5pu8szGTJ7T3HjFjM8NBrcTnfsFJauM",
  authDomain: "signwise-2024.firebaseapp.com",
  projectId: "signwise-2024",
  storageBucket: "signwise-2024.appspot.com",
  messagingSenderId: "219419102604",
  appId: "1:219419102604:web:71614f15538e739cc65aa9",
  measurementId: "G-6H420S4RKJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
// export default Storage = getStorage(app)
//  const analytics = getAnalytics(app);


