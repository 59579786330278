import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { AttachMoney, BlockOutlined, Description, SaveAlt, TaskAltOutlined, Visibility } from '@material-ui/icons';
// material
import { useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../../official-request/ConfirmationPopup'
// ----------------------------------------------------------------------

ReceiveOrderMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.number
};

export default function ReceiveOrderMenu({ onDelete, userName, id, status , onReceive, onRefresh, onInvoice, onPayment }) {
  const ref = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false);
  const [openDeletePop,setOpenDeletePop] =useState(false)
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const userType = userData.user_type
  const explodedPermissions =userData?.exploded_permissions

  const changeStatus = async (cancelId) => {
    try {
      let orderId = cancelId;
      let response = await axios.post(`${REST_API_END_POINT}products/cancel-receive-order`,{orderId,userId})
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        onRefresh(true)
        navigate(PATH_DASHBOARD.receiveOrder.root);
      }
      else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.receiveOrder.root);
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuItemClick = async (id) => {
    setIsOpen(false)
    setOpenDeletePop(false);
    await changeStatus(id);
    navigate(PATH_DASHBOARD.receiveOrder.root);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {(onReceive && status === 0) && (
          <MenuItem
          disabled={explodedPermissions?.some(permission => ['Receive Order'].includes(permission)) ? false : true}
          onClick={() => {onReceive(id); setIsOpen(false);}}
          >
            <ListItemIcon>
              <SaveAlt sx={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary="Receive order" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {(status === 0 && userType !==3) && <MenuItem
          disabled={explodedPermissions?.some(permission => ['Edit Order'].includes(permission)) ? false : true}
          component={RouterLink}
          to={`${PATH_DASHBOARD.receiveOrder.edit}?id=${id}`}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit order" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {(status === 0 && userType !==3) && <MenuItem
          disabled={explodedPermissions?.some(permission => ['Cancel Order'].includes(permission)) ? false : true}
          onClick={()=>{setOpenDeletePop(true),setIsOpen(false)}}
          >
            <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Cancel Order" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>}

          {(status !== 0 && userType !==3) && <MenuItem
          disabled={explodedPermissions?.some(permission => ['View Order'].includes(permission)) ? false : true}
          onClick={() => {onReceive(id); setIsOpen(false);}} >
          <ListItemIcon>
            <Visibility sx={{ width: 25, height: 25 }} />
          </ListItemIcon>
          <ListItemText primary="View order" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {(status !== 0 || userType ==3) && <MenuItem
        disabled={(explodedPermissions?.some(permission => ['View Invoice'].includes(permission)) || userType == 3) ? false : true}
        onClick={() => {onInvoice(id); setIsOpen(false);}}>
          <ListItemIcon>
            <Description sx={{ width: 25, height: 25 }} />
          </ListItemIcon>
          <ListItemText primary="Invoice" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {(status === 1 && userType !==3) && <MenuItem
          disabled={explodedPermissions?.some(permission => ['Make payment'].includes(permission)) ? false : true}
          onClick={() => {onPayment(id); setIsOpen(false);}}>
          <ListItemIcon>
            <AttachMoney sx={{ width: 25, height: 25 }} />
          </ListItemIcon>
          <ListItemText primary="Make Payment" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

      </Menu>

    {/* Confirmation for cancel order  */}
    <ConfirmationPopup 
      open={openDeletePop}
      handleClose={()=>{setOpenDeletePop(false)}}
      message={`You want to cancel order ${userName} ! `}
      handleClick={()=>{handleMenuItemClick(id)}}
    />
    </>
  );
}
