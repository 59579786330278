import * as Yup from 'yup'
// import PropTypes from 'prop-types';
import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Container,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Autocomplete,
  Checkbox,
  Chip,
} from '@material-ui/core'

//component
import Scrollbar from 'src/components/Scrollbar'
import Page from 'src/components/Page'
import { UserListHead, UserMoreMenu } from '../../user/list'
import Label from 'src/components/Label'
import { sentenceCase } from 'change-case'
import SearchNotFound from 'src/components/SearchNotFound'
import CampaignSearch from './CampaignSearch'
import { filter } from 'lodash'
import moment from 'moment'
import { getStatusActivation } from 'src/common/Helper'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { PATH_DASHBOARD } from 'src/routes/paths'
import ConfirmationPopup from 'src/components/confirmationPopup'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'

const STATUS = [
  { id: 4, value: 'Planning' },
  { id: 1, value: 'Active ' },
  { id: 2, value: 'In Active' },
  { id: 3, value: 'Completed' },
]
const TABLE_HEAD = [
  { id: 0, label: 'Campaign Name', alignRight: false },
  { id: 1, label: 'Updated By', alignRight: false },
  { id: 2, label: 'Last Updated Time', alignRight: false },
  { id: 4, label: 'Status', alignRight: false },
  { id: 5 },
]

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis?.map((el) => el[0])
}
export default function Createcampaign({
  allCampaigns,
  setRefresh,
  refresh,
  setPage,
  page,
  rowsPerPage,
  setRowsPerPage,
  setFilterName,
  filterName,
  totalCount,
  fetchAllCampaigns,
}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [add, setAdd] = useState(false)
  const [selected, setSelected] = useState([])
  const [tags, setTags] = useState([])
  const [isEdit, setIsEdit] = useState({ id: null, type: false })
  const [isDelete, setIsDelete] = useState({ id: null, type: false, name: '' })
  const [isDeactivate, setDeactivate] = useState({
    type: null,
    id: null,
    name: '',
    status: null,
  })
  const [inputValue, setInputValue] = useState('')
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [initialData, setInitialData] = useState([])
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  let user = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =user?.exploded_permissions
  //
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Campaign Name is required'),
    start: Yup.mixed().required('Start Date is required'),
    end: Yup.date()
      .nullable()
      .when('start', (start, schema) => {
        return start
          ? schema
              .min(start, 'End Date must be greater than the Start Date')
              .required('End Date is required')
              .notOneOf(
                [start],
                'End Date must be different from the Start Date',
              )
          : schema
      })
      .nullable(false)
      .required('End Date is required'),
  })

  //
  const handleEdit = (id) => {
    setAdd(true)
    setIsEdit({ type: true, id: id })
    fetchsavedData(id)
  }
  const fetchsavedData = async (id) => {
    try {
      const res = await axios.post(
        `${REST_API_END_POINT}campaign/get-campaign-by-id`,
        { campaignId: id },
      )

      setInitialData(...res.data.result)
      const targetList = res.data.result[0]?.target_list

      setTags(targetList?.split(',')?.map((tag) => tag?.trim())||[])
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteCampaign = (id, name) => {
    setIsDelete({ type: true, id: id, name: name })
  }
  const handleDeactivate = (id, name, status) => {
    setDeactivate({ type: true, id: id, name: name, status: status })
  }
  const handlecampaignStatus = async (status, id) => {
    let res = await axios.post(
      `${REST_API_END_POINT}campaign/delete-campaign`,
      {
        campaignId: id,
        userId: user.user_id,
        userType: user.user_type,
        status,
      },
    )
    if (res.data.status === 'success') {
      enqueueSnackbar(res.data.notification, { variant: 'success' })
      setIsDelete({ type: false, id: null, name: '' })
      setDeactivate({ type: false, id: null, name: '' })
    } else {
      enqueueSnackbar(res.data.message, { variant: 'error' })
      setIsDelete({ type: false, id: null, name: '' })
      setDeactivate({ type: false, id: null, name: '' })
    }
    setRefresh(!refresh)
  }
  const initialStatus = STATUS.find(
    (dat) => dat?.id === initialData?.campaign_status,
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialData.name ? initialData.name : '',
      budget: initialData.budget ? initialData.budget : '',
      start: initialData.start_date
        ? moment.unix(initialData.start_date)
        : currentDate,
      end: initialData.end_date ? moment.unix(initialData.end_date) : '',
      description: initialData.description ? initialData.description : '',
      status: initialData.campaign_status ? initialStatus : null,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        values.tags = tags?.join(',')

        if (isEdit.type === true) {
          axios
            .post(`${REST_API_END_POINT}campaign/edit-campaign`, {
              userId: user.user_id,
              values,
              campaignId: isEdit.id,
            })
            .then((res) => {
              if (res.data.status === 1) {
                setSubmitting(false)
                resetForm()
                enqueueSnackbar(res.data.message, { variant: 'success' })
                setRefresh(!refresh)
                setTags([])
              } else {
                setSubmitting(false)
                enqueueSnackbar('Failed to create new campaign', {
                  variant: 'error',
                })
              }
              setIsEdit({ type: false, id: null })
              setAdd(false)
            })
        } else {
          const res = await axios.post(
            `${REST_API_END_POINT}campaign/create-new`,
            {
              userId: user.user_id,
              values,
            },
          )
          if (res.data.status === 1) {
            setSubmitting(false)
            enqueueSnackbar(res.data.message, { variant: 'success' })
            setRefresh(!refresh)
            setTags([])
          } else {
            setSubmitting(false)
            enqueueSnackbar('Failed to create new campaign', {
              variant: 'error',
            })
          }
          setAdd(false)
          resetForm()
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags?.filter((tag) => tag !== tagToDelete))
  }
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allCampaigns?.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
    fetchAllCampaigns(event.target.value)
  }
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0
    const filteredUsers = allCampaigns
  const isUserNotFound = filteredUsers?.length === undefined

  return (
    <>
      {add ? (
        <Page title="Create Campaigns | Sign-Wise Solution">
        <HeaderBreadcrumbs heading={isEdit?.type === true ? "Edit Campaign" : "Add Campaign" } />
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={10}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Campaign Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <TextField
                        fullWidth
                        label="Type"
                        placeholder="Type"
                        value={'Email'}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        fullWidth
                        type="number"
                        label="Budget"
                        {...getFieldProps('budget')}
                        error={Boolean(touched.budget && errors.budget)}
                        helperText={touched.budget && errors.budget}
                      />
                      <Autocomplete
                        freeSolo
                        multiple
                        fullWidth
                        id="tags-outlined"
                        options={tags}
                        value={tags || []}
                        onChange={(event, newValue) => setTags(newValue)}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="TargetList"
                            onKeyDown={handleKeyDown}
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag}
                              onDelete={() => handleDelete(tag)}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <Box
                        component={MobileDatePicker}
                        label="Start Date"
                        minDate={moment()}
                        value={values.start}
                        onChange={(date) =>
                          setFieldValue(
                            'start',
                            moment(date).format('YYYY-MM-DD'),
                          )
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                      <Box
                        component={MobileDatePicker}
                        label="End Date"
                        minDate={moment()}
                        value={values.end}
                        onChange={(date) =>
                          setFieldValue(
                            'end',
                            moment(date).format('YYYY-MM-DD'),
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            fullWidth
                            error={Boolean(touched.end && errors.end)}
                            helperText={touched.end && errors.end}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        value={values.status}
                        options={STATUS}
                        onChange={(event, newValue) =>
                          setFieldValue('status', newValue)
                        }
                        getOptionLabel={(state) => state.value}
                        // sx={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )} // Update the label
                      />
                    </Stack>

                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={9}
                      label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />

                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {isEdit.type === true ? 'Update' : 'Save'}
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setAdd(false)
                          setInitialData({})
                          setIsEdit({ type: false, id: null })
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
        </Page>
      ) : (
        <Page title="Campaigns | Sign-Wise Solution">
          <HeaderBreadcrumbs heading="All Campaigns" />
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              marginBottom={2}
              justifyContent="flex-end"
            >
              <Button
                disabled={explodedPermissions?.some(permission => ['Add Campaign'].includes(permission)) ? false : true}
                variant="outlined"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => {
                  setAdd(!add)
                }}
              >
                Add Campaign
              </Button>
            </Stack>
            <Card>
              <CampaignSearch
                campaign
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      showAllCheckBox
                      headLabel={TABLE_HEAD}
                      rowCount={allCampaigns?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {applySortFilter(filteredUsers,getComparator(order, orderBy))
                        ?.map((row) => {
                          const { id, name, status, added_by, updated_time } =
                            row
                          const isItemSelected = selected.indexOf(id) !== -1

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>

                              <TableCell align="center">
                                <Typography
                                  sx={{ cursor: 'pointer' }}
                                  variant="subtitle1"
                                  onClick={() =>
                                    navigate(
                                      `${PATH_DASHBOARD.crm.viewcampaign}/${id}`,
                                    )
                                  }
                                >
                                  {name}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                {sentenceCase(added_by)}
                              </TableCell>
                              <TableCell align="center">
                                {moment.unix(updated_time).format('MM/DD/YYYY')}
                              </TableCell>
                              <TableCell align="center">
                                <Label
                                  variant={
                                    theme.palette.mode === 'light'
                                      ? 'ghost'
                                      : 'filled'
                                  }
                                  color={getStatusActivation(status).color}
                                >
                                  {getStatusActivation(status).label}
                                </Label>
                              </TableCell>

                              <TableCell align="right">
                                <UserMoreMenu
                                  onEdit={() => {
                                    if(explodedPermissions?.some(permission => ['Edit Campaign'].includes(permission))){
                                    handleEdit(id)
                                    }else{
                                      enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                    }
                                  }}
                                  status={status}
                                  onDelete={() =>{
                                    if(explodedPermissions?.some(permission => ['Delete Campaign'].includes(permission))){
                                      handleDeleteCampaign(id, name)
                                    }else{
                                      enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                    }
                                  }}
                                  onStatusChange={() =>{
                                    if(explodedPermissions?.some(permission => ['Activate/Deactivate Campaign'].includes(permission))){
                                      handleDeactivate( id, name, status == 0 ? 1 : 0, )
                                    }else{
                                      enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <ConfirmationPopup
                open={isDelete.type === true}
                handleClick={() => {
                  handlecampaignStatus(2, isDelete.id)
                }}
                handleClose={() => {
                  setIsDelete({ type: false, id: null, name: '' })
                }}
                message={`You want to delete this "${isDelete.name}" `}
              />

              <ConfirmationPopup
                open={isDeactivate.type === true}
                handleClick={() => {
                  handlecampaignStatus(isDeactivate.status, isDeactivate.id)
                }}
                handleClose={() => {
                  setDeactivate({
                    type: false,
                    id: null,
                    name: '',
                    status: null,
                  })
                }}
                message={`You want to make this "${isDeactivate.name} ${
                  isDeactivate.status == 0 ? 'Deactivate' : 'Activate'
                } `}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  )
}
