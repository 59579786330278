import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import clipboardFill from '@iconify/icons-eva/clipboard-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import gridFill from '@iconify/icons-eva/grid-fill';
import questionmarkcircleFill from '@iconify/icons-eva/question-mark-circle-fill';
import teamFill from '@iconify/icons-ant-design/team-outline';
import filetextFill from '@iconify/icons-eva/file-text-fill';
import { capitalCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_DASHBOARD, PATH_PAGE, PATH_SETTINGS,ROOTS_PCB } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import {  ApartmentOutlined ,AdminPanelSettings, SupportOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: homeFill,
//     linkTo: '/dashboard/calendar'
//   },
//   {
//     label: 'Departments',
//     icon: gridFill,
//     linkTo: PATH_DASHBOARD.departments.root
//   },
//   {
//     label: 'Sign Agreements',
//     icon: clipboardFill,
//     linkTo: PATH_DASHBOARD.signagreements.root
//   },
//   {
//     label: 'Team Members',
//     icon: teamFill,
//     linkTo: PATH_DASHBOARD.callCenter.teams,
//     type : 1
//   },
//   {
//     label: 'Custom Field Template',
//     icon: filetextFill,
//     linkTo: PATH_DASHBOARD.customfieldtemplate.root
//   },
//   {
//     label: 'Questionnaire Templates',
//     icon: questionmarkcircleFill,
//     linkTo: PATH_DASHBOARD.QuestionnaireTemplates.root
//   },
//   {
//     label: 'Profile',
//     icon: personFill,
//     linkTo: PATH_DASHBOARD.client.profile,
//     type : 1
//   },
//   {
//     label: 'Settings',
//     icon: settings2Fill,
//     linkTo: PATH_SETTINGS,
//     type : 1
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem('user'))
  const userType = userData.user_type;
  const userPermission = userData.user_permissions;
  const explodedPermissions = userData.exploded_permissions;
  // ----------------------------------------------------------------------
 
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const checkPermission = (userPermissions, requiredPermissions) => {
    return requiredPermissions.every(permission => userPermissions.includes(permission));
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 270 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
          {userData.first_name+' '+ userData.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData.isVendor ? 'Vendor' : userData?.user_type == 3 ? 'Client' : capitalCase(userData.user_role)}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS
        .filter(item => userData.user_type === 1 || item.type === 1 || (item.permissionLabel && explodedPermissions.includes(item.permissionLabel)))
        .map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            {option.label}
          </MenuItem>
        ))} */}

    <MenuItem
      key="Home"
      to={ROOTS_PCB}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={homeFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Home
    </MenuItem>
    {/* {userType !== 3 &&
  checkPermission(explodedPermissions, ['Add Department', 'Deactivate Department','Delete Department','View Department','Edit Department']) && (
    <MenuItem
      key="Departments"
      to={PATH_DASHBOARD.callCenter.departments}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={gridFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Departments
    </MenuItem>
)} */}
{/* {userType !== 3 &&
  checkPermission(explodedPermissions, ['Create Custom Field Template','Delete Custom Field Template']) && (
    <MenuItem
      key="Sign Agreements"
      to={PATH_DASHBOARD.signagreements.root}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={clipboardFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Sign Agreements
    </MenuItem>
  )} */}
    {/* {userType !== 3 &&
  checkPermission(explodedPermissions, ['Inviting team members']) && (
    <MenuItem
      key="Team members"
      to={PATH_DASHBOARD.callCenter.teams}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={teamFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Team members
    </MenuItem>
  )} */}


{/* {userType !== 3 &&
  checkPermission(explodedPermissions, ['Create Custom Field Template','Delete Custom Field Template']) && (
    <MenuItem
      key="Custom Field Template"
      to={PATH_DASHBOARD.customfieldtemplate.root}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={filetextFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Custom Field Template
    </MenuItem>
  )} */}
{/* {userType !== 3 &&
  checkPermission(explodedPermissions, ['Create Questionnaire Template','Delete Questionnaire Template']) && (
    <MenuItem
      key="Questionnaire Templates"
      to={PATH_DASHBOARD.QuestionnaireTemplates.root}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={questionmarkcircleFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Questionnaire Templates
    </MenuItem>
  )} */}
{userType !== 1 &&
    <MenuItem
      key="Profile"
      to={PATH_DASHBOARD.client.profile}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={personFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Profile
    </MenuItem>
  }
      {userType === 1 &&
    <MenuItem
      key="Admin Profile"
      to={PATH_DASHBOARD.adminAccount.root}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={personFill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Profile
    </MenuItem>
  }
  {(userType !== 3 && 
    (userType === 1 || (explodedPermissions?.some(permission => ['Time Tracking','Manage Clock-In-Out Time Settings','Production Control Board Settings','Sample Reports','Billing','Company Profile','Company Social Links','Localization','Integrations','Workspace Options','View All User Roles','Delete User Roles','Activate/Deactivate User Roles','Edit User Roles','Add User Roles','Contact Tags','Manage Profile Information','Client Ranking','Skills & Services','Client Type'].includes(permission))))) &&
    <MenuItem
      key="Settings"
      to={PATH_SETTINGS}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
      <Box
        component={Icon}
        icon={settings2Fill}
        sx={{
          mr: 2,
          width: 24,
          height: 24
        }}
      />
      Settings
    </MenuItem>
  }
    {userType === 1 ?(
    null
  ):userType === 3 ?
    (
      <MenuItem
      key="Support Ticket"
      to={PATH_DASHBOARD.crm.ticket}
      component={RouterLink}
      onClick={handleClose}
      sx={{ typography: 'body2', py: 1, px: 2.5 }}
    >
     <SupportOutlined 
     sx={{
      mr: 2,
      width: 25,
      height: 25
    }}
     />
    Support Ticket
    </MenuItem>
    ):null
  }
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
