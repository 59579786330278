import React from 'react';
import {Typography, Grid, Paper,} from '@material-ui/core';

const OrderTracker = ({paperData,setMessage}) => {
  const handlePaperClick = (data) => {
    // Handle the click event, e.g., navigate to a different page
    setMessage(data.text)
    // console.log(`Paper ${data} clicked`);
  };

  // const paperData = [
  //   { id: 1, text: 'Track My Order' },
  //   { id: 2, text: 'Make A New Order' },
  //   { id: 3, text: 'Hear More About Signboards' },
  //   { id: 4, text: 'Request For A New Design' },
  //   { id: 5, text: 'Why Branding Is Needed' },
  // ];

  return (
    <>
    <Grid sx={{ mt: 6, }} xs={12} md={12}>
      {paperData?.map((data) => (
        <Paper
          key={data.id}
          sx={{
            marginTop:2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 1,
            borderRadius: '30px',
            cursor: 'pointer', // Make the Paper clickable
            marginBottom: 2, // Add a gap between papers
            bgcolor: 'background.neutral',
          }}
          onClick={() => handlePaperClick(data)}
        >
          <Typography sx={{ color: '#1ccaff', fontSize: '0.875rem', fontWeight: 'medium' }}>
            {data.text}
          </Typography>
        </Paper>
      ))}
      </Grid>
    </>
  );
};

export default OrderTracker;
