import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  TextField,
  InputAdornment,
  Container,
  Paper,
  Typography,
  Stack,
  Divider,
  Avatar,
  Box,
  Button,
  Grid,
  Popover,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import Slider from 'react-slick';
import { REST_API } from 'src/constants/Defaultvalues';
import roundSend from '@iconify/icons-ic/round-send';
import { Icon } from '@iconify/react';
import Gif from 'src/Gif/chat-ai.gif';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
import EmojiPicker from 'src/components/EmojiPicker';
import { Search,Fullscreen,Minimize } from '@material-ui/icons';
import roundFullscreen from '@iconify/icons-ic/round-fullscreen';
import roundFullscreenExit from '@iconify/icons-ic/round-fullscreen-exit';
import { MIconButton } from 'src/components/@material-extend/index.js';
import axios from 'axios';
import ChatBotEmojiPicker from 'src/components/ChatBotEmojiPicker';
import { COMPANY_LOGO } from "src/constants/Defaultvalues";

const imgSrc = [
  { content:'Sure, i will make new order for you...', src: 'https://www.usatoday.com/gcdn/-mm-/b47f194f3e7d8fc4c0174176cb3b826ddcdca22d/c=0-62-1000-624/local/-/media/2020/04/30/USATODAY/usatsports/MotleyFool-TMOT-6d945cda-baskin-robbins.jpg?width=1200&disable=upscale&format=pjpg&auto=webp'},
  { content:'Sure, i will make new order for you...', src: 'https://image.cnbcfm.com/api/v1/image/107007653-16433170092022-01-27t205009z_350782834_rc2v7s9l3xsv_rtrmadp_0_mcdonalds-results.jpeg?v=1643317121&w=1920&h=1080'},
  { content:'Sure, i will make new order for you...', src: 'https://metrodetroitsigns.com/wp-content/uploads/2019/07/parker-box-signs2-e1572756850453.jpg'},
  { content:'Signboards are used for branding for a product some examples given below', src: "https://i.pinimg.com/736x/6f/67/7f/6f677fa116408cbb29a790e971aebb11.jpg"},
  { content:'i will approve a new design for you...', src: 'https://us.123rf.com/450wm/jetcityimage/jetcityimage1903/jetcityimage190300077/125083959-indianapolis-circa-march-2019-pizza-hut-fast-casual-restaurant-pizza-hut-is-a-subsidiary-of-yum.jpg?ver=6'},
  { content:'i will approve a new design for you...', src: 'https://us.123rf.com/450wm/dipressionist/dipressionist2205/dipressionist220500091/189169566-2021-logotype-nike-sportswear-co-at-boutique.jpg?ver=6'},
  { content:'i will approve a new design for you...', src: 'https://images.jdmagicbox.com/quickquotes/images_main/acrylic-sign-board-2019031267-cpr4wqti.jpg'},
  { content:'i will approve a new design for you...', src: 'https://i.pinimg.com/originals/28/5e/22/285e2220bc90c2696cbba06be058df1f.jpg'},
  
]

const ChatBot = () => {
  const [messages, setMessages] = useState([
    { content: 'Hi there! Atom here, how can I help you?', role: 'assistant' },
  ]);
  const [input, setInput] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // Added search query state
  // const img = 'https://prod-images.tcm.com/Master-Profile-Images/LeonardoDiCaprio.jpg';
  const img = '/static/mock-images/avatars/avatar_default1.jpg';
  const [isThinking, setIsThinking] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [currentCom, setCurrentCom] = useState('trackOrder');
  const [showTrackOrder, setShowTrackOrder] = useState(true); // Set to true to display Track Your Order initially
  const [anchorEl, setAnchorEl] = useState(null);
  const messagesContainerRef = useRef(null);
  // const [imageUrls, setImageUrls] = useState([]);
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [flag,setFlag] = useState('')

  const isDesktop = useMediaQuery('(min-width:571px)');
  const isMobile = useMediaQuery('(max-width: 571px)');


  const [isProfileSectionActive, setIsProfileSectionActive] = useState(false);


  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    if (isDesktop) {
      setIsFullScreen((prev) => !prev);
    }
  };

  useEffect(() => {
    // Only send the message to the API if there are messages other than the initial one
    if (messages.length > 1) {
      handleSendBotMessage();
    }
  }, [flag]);

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // const handleAttachImage = () => {
  //   // Trigger the click event of the hidden file input
  //   if (inputFileRef.current) {
  //     inputFileRef.current.click();
  //   }
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   // Handle the selected file (you may want to upload or display it)
  //   console.log('Selected file:', file);
  // };

  // const inputFileRef = useRef(null);

  const [selectedEmoji, setSelectedEmoji] = useState('');

  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji.native);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.primary.chatbotBack,
      padding: theme.spacing(1),
      borderRadius: 30,
      margin: theme.spacing(2),
    },
    text: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 'medium',
    },
  }));
  
  const classes = useStyles();

  const handleSendBotMessage = async () => {
    console.log('msg here-----', messages);
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const result = await axios.post(REST_API + 'chatbot', {
        prompt: messages,
        user: user,
      });

      if (result) {
        const data = result.data.message;
        console.log('res data here------', data);
        setMessages(data.filter((d) => d.role !== 'tool' && d.content !== null));
        setIsThinking(false);
      }
    } catch (error) {
      console.error(error);
    }

    setInput('');
    setSearchQuery(''); // Clear search query after sending a message

  }
    
  const handleSendMessage = async () => {
    setCurrentCom('chat');
    setShowChat(true);
    setShowTrackOrder(false);
  
    const userMessage = { content: input, role: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    const prompt = searchQuery ? `${input} ${searchQuery}` : input;
  
    // Display "thinking..." message
    setIsThinking(true);
    setFlag(prompt);
   
  
    // Simulate a delay before getting the bot's response
    // setTimeout(() => {
    //   // Actual bot response based on user's message
    //   let botMessage;
    //   const lowerCaseInput = input.toLowerCase();
  
    //   if (lowerCaseInput.includes('track my order')) {
    //     botMessage = { text: 'Sure, send me your order ID ...', sender: 'bot' };
    //     setShowTrackOrder(false);
    //     setShowChat(true);
    //   } else if (lowerCaseInput.includes('order no: 123')) {
    //     botMessage = { text: 'Your order is in progress...', sender: 'bot' };
    //   }else if (lowerCaseInput.includes('make a new order for me')) {
    //     botMessage = { text: 'Sure, I will make a new order for you...', sender: 'bot' };
    //   } else if (lowerCaseInput.includes('hear more about signboards')) {
    //     botMessage = { text: 'Signboards are used for branding. Some examples are...', sender: 'bot' };
    //   } else if (lowerCaseInput.includes('request for a new design')) {
    //     botMessage = { text: 'I will approve a new design for you...', sender: 'bot' };
    //   } else if (lowerCaseInput.includes('why branding is needed')) {
    //     botMessage = { text: 'Branding is essential to identify duplicate products in the market...', sender: 'bot' };
    //   }else if (lowerCaseInput.includes('thank you atom')) {
    //     botMessage = { text: 'You are welcome', sender: 'bot' };
    //   } else {
    //     botMessage = { text: "Sorry, I didn't understand your message.", sender: 'bot' };
    //   }
  
    //   setMessages((prevMessages) => [...prevMessages, botMessage]);
    //   setIsThinking(false); // Set thinking to false when the response arrives
    // }, 2000);
  
    setInput('');
  };
  
  
  

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleToggleChat = () => {
    setCurrentCom('chat')
    setShowChat((prevShowChat) => !prevShowChat);
    setShowTrackOrder(false);

    // Add a predefined message when "Track Your Order" is clicked
    const trackOrderMessage = { content: 'Hey Atom, track my order', role: 'user' };
    setMessages((prevMessages) => [...prevMessages, trackOrderMessage]);

    // Simulate a delay before getting the bot's response
    setIsThinking(true);
    setTimeout(() => {
      // Actual bot response
      const botMessage = { content: 'Sure, send me your order ID ...', role: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsThinking(false);
    }, 2000);
  };

  const handleMakeOrder = () => {
    setCurrentCom('chat')
    setShowChat((prevShowChat) => !prevShowChat);
    setShowTrackOrder(false);

    // Add a predefined message when "Track Your Order" is clicked
    const trackOrderMessage = { content: 'Hey Atom, make a new order', role: 'user' };
    setMessages((prevMessages) => [...prevMessages, trackOrderMessage]);

    // Simulate a delay before getting the bot's response
    setIsThinking(true);
    setTimeout(() => {
      // Actual bot response
      const botMessage = { content: 'Sure, i will make new order for you...', role: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsThinking(false);
    }, 2000);
  };

  const handleMoreSignboards = () => {
    setCurrentCom('chat')
    setShowChat((prevShowChat) => !prevShowChat);
    setShowTrackOrder(false);

    // Add a predefined message when "Track Your Order" is clicked
    const trackOrderMessage = { content: 'Hear More about signboards', role: 'user' };
    setMessages((prevMessages) => [...prevMessages, trackOrderMessage]);

    // Simulate a delay before getting the bot's response
    setIsThinking(true);
    setTimeout(() => {
      // Actual bot response
      const botMessage = { content: 'Signboards are used for branding for a product some examples given below', role: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsThinking(false);
    }, 2000);
  };

  const handleRequestDesign = () => {
    setCurrentCom('chat')
    setShowChat((prevShowChat) => !prevShowChat);
    setShowTrackOrder(false);

    // Add a predefined message when "Track Your Order" is clicked
    const trackOrderMessage = { content: 'Hey Atom, Provide a new design', role: 'user' };
    setMessages((prevMessages) => [...prevMessages, trackOrderMessage]);

    // Simulate a delay before getting the bot's response
    setIsThinking(true);
    setTimeout(() => {
      // Actual bot response
      const botMessage = { content: 'i will approve a new design for you...', role: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsThinking(false);
    }, 2000);
  };

  const handleWhyBranding = () => {
    setCurrentCom('chat')
    setShowChat((prevShowChat) => !prevShowChat);
    setShowTrackOrder(false);

    // Add a predefined message when "Track Your Order" is clicked
    const trackOrderMessage = { content: 'Why branding is needed ?', role: 'user' };
    setMessages((prevMessages) => [...prevMessages, trackOrderMessage]);

    // Simulate a delay before getting the bot's response
    setIsThinking(true);
    setTimeout(() => {
      // Actual bot response
      const botMessage = { content: 'Branding is to identify the duplicate products in the market...', role: 'assistant' };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsThinking(false);
    }, 2000);
  };

  const handleToggleForm = () => {
    if (showForm) {
      setShowChat(currentCom === 'chat' ? true : false);
      setShowTrackOrder(currentCom === 'chat' ? false : true);
    } else {
      setShowChat(false);
      setShowTrackOrder(false);
    }
    setShowForm(!showForm);
    setIsProfileSectionActive(!showForm);
    setShowSearchInput(false); // Add this line to hide search input
  };

  const handleToggleTrackOrder = () => {
    setCurrentCom('trackOrder');
    setShowTrackOrder(true);
    setShowChat(false);
    setShowForm(false);
    setShowSearchInput(false); // Add this line to hide search input
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setShowSearchInput(false); // Add this line to hide search input
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setShowSearchInput(false); // Add this line to hide search input
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const formatMessageText = (content, role) => {
    const messageStyle = {
      display: 'inline-block',
      padding: '8px 12px',
      borderRadius: '8px',
      backgroundColor: role === 'user' ? '#1ccaff' : '#172e34',
      color: role === 'user' ? 'white' : '#919eab',
      marginBottom: '25px',
      wordWrap: 'break-word',
      maxWidth: '60%',
    };
  
    const timestampStyle = {
      fontSize: '0.8rem',
      marginTop: '5px',
      color: role === 'user' ? 'white' : '#919eab',
    };
  
    const getCurrentTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };
  
    // Extract URLs from the message content using the provided regex
    const urls = content.match(urlRegex);
  
    return (
      <Box sx={{ position: 'relative', textAlign: role === 'user' ? 'right' : 'left' }}>
        {role === 'assistant' && (
          <img
            src={Gif}
            alt="Bot Avatar"
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '30px',
              width: '30px',
            }}
          />
        )}
        {role === 'user' && (
          <img
            src={COMPANY_LOGO}
            alt="User Avatar"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              border: '2px solid #1ccaff',
            }}
          />
        )}
        <Box
          sx={{
            ...messageStyle,
            textAlign: role === 'user' ? 'left' : 'left',
            marginLeft: role === 'assistant' ? '35px' : 0,
            marginRight: role === 'user' ? '50px' : 0,
          }}
        >
          <div>
            {content.split(urlRegex).map((part, index) => (
              index % 2 === 0 ? (
                <span key={index}>{part}</span>
              ) : (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                  {part}
                </a>
              )
            ))}
          </div>
          <Box width={'100%'} display={'flex'} justifyContent={role === 'user' ? 'flex-end' : 'flex-end'}>
            <div style={timestampStyle}>{getCurrentTime()}</div>
          </Box>
        </Box>
        {role === 'assistant' && urls && urls.length > 0 && (
          <Box
            sx={{
              ...messageStyle,
              textAlign: 'left',
              marginLeft: '35px',
              marginRight: '0',
              minWidth: '70%',
            }}
          >
            <Slider showThumbs={false}>
              {urls.map((url, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <img
                    style={{ height: '180px', width: '200px' }}
                    src={url}
                    alt={`Bot Image ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
            <Box width={'100%'} display={'flex'} justifyContent={role === 'user' ? 'flex-start' : 'flex-end'}>
              <div style={timestampStyle}>{getCurrentTime()}</div>
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  

  

  const renderContent = () => {
    // Filter messages based on the search query
    const filteredMessages = messages.filter((message) =>
      message.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
      return (
        <>

{showSearchInput && (
          <TextField
            size="small"
            fullWidth
            id="search"
            placeholder="Search..."
            name="search"
            sx={{ flex: 1, mb: 2 }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        )}
      {/* //ChatSection */}
        {showChat && <Paper
          ref={messagesContainerRef} // Attach the ref to the Paper element
          sx={{
            mb: 5,
            height: '300px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '4px',
            },
          }}
        >
          {filteredMessages.map((message, index) => (
              <Box key={index} sx={{ textAlign: message.role === 'user' ? 'right' : 'left' }}>
                {formatMessageText(message.content, message.role)}
              </Box>
            ))}
          {isThinking && (
              <Box sx={{ textAlign: 'left' }}>{formatMessageText('Thinking...', 'assistant')}</Box>
            )}
        </Paper>}
        <Box sx={{ display: 'flex', justifyContent: 'center',mt:-3 }}>
      {/* //FirstSection */}
        {showTrackOrder && <Grid sx={{height:'300px',width:'300px'}}>
          <Paper className={classes.root} onClick={handleToggleChat}>
            <Typography className={classes.text} variant="body2" align="center">
              {showTrackOrder ? 'Track Your Order' : 'Track Your Order'}
            </Typography>
          </Paper>

          <Paper className={classes.root} onClick={handleMakeOrder}>
            <Typography className={classes.text} variant="body2" align="center">
              {showTrackOrder ? 'Make A New Order' : 'Make A New Order'}
            </Typography>
          </Paper>

          <Paper className={classes.root} onClick={handleMoreSignboards}>
            <Typography className={classes.text} variant="body2" align="center">
              {showTrackOrder ? 'Hear More About Signboards' : 'Hear More About Signboards'}
            </Typography>
          </Paper>

          <Paper className={classes.root} onClick={handleRequestDesign}>
            <Typography className={classes.text} variant="body2" align="center">
              {showTrackOrder ? 'Request For A New Design' : 'Request For A New Design'}
            </Typography>
          </Paper>

          <Paper className={classes.root} onClick={handleWhyBranding}>
            <Typography className={classes.text} variant="body2" align="center">
              {showTrackOrder ? 'Why Branding Is Needed' : 'Why Branding Is Needed'}
            </Typography>
          </Paper>
        </Grid>}
        </Box>
      {/* //ProfileSection */}
        {showForm && <Grid container spacing={2} sx={{height:'300px',p:2}}>
          <Grid item xs={6}>
            <TextField label="First Name" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Last Name" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Phone" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Email" fullWidth />
          </Grid>
          <Grid item xs={12} sx={{mb:3,display:'flex', justifyContent:'flex-end',gap:1}}>
          <Button variant="outlined" color="primary" onClick={handleToggleForm}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleToggleForm}>
              Save
            </Button>
          </Grid>
        </Grid>}
        </>
      )}

  return (
    <>
       <MIconButton onClick={handleOpenPopover} sx={{ padding: 0, width: 44, height: 44, top: 3 }}>
        <img alt='icon' width='50px' height='50px' src={Gif}/>
      </MIconButton>
      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // sx={{mt:-7}}
      >
        <Container component="main" maxWidth={isFullScreen ? 'xl' : 'xs'}>
          <Paper sx={{ pt: 2, width: isFullScreen ? '500px' : '300px' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img
                  src={Gif} // Replace with the URL of Atom's avatar
                  alt="Atom Avatar"
                  style={{
                    height: '40px', // Adjust the height of the image as needed
                    width: '40px', // Adjust the width of the image as needed
                    marginRight: '10px',
                  }}
                  onClick={handleToggleTrackOrder}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Atom
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: '8px',
                        height: '8px',
                        backgroundColor: 'green',
                        borderRadius: '50%',
                        marginRight: '4px',
                      }}
                    />
                    <Typography variant="caption" color="textSecondary">
                      Online
                    </Typography>
                  </Box>
                </Box>
                <Stack pl={2} style={{ display: showChat && !showForm ? 'flex' : 'none' }}>
                  <MIconButton onClick={() => setShowSearchInput(!showSearchInput)}>
                    <Search />
                  </MIconButton>
                </Stack>


                <Stack pl={2}>
                  {isDesktop && (
                    <MIconButton onClick={handleFullScreenToggle}>
                      <Icon icon={isFullScreen ? roundFullscreenExit : roundFullscreen} width={24} height={24} />
                    </MIconButton>
                  )}
                </Stack>
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: -9 }}>
                <IconButton onClick={handleToggleForm}>
                  <Avatar alt="John" sx={{ width: 40, height: 40 }} src={COMPANY_LOGO} />
                </IconButton>
              </Box>
            </Box>

            <Divider sx={{ mb: 3 }} />

           {renderContent()}

            <Divider sx={{ mb: 1 }} />

            <Stack direction="row" alignItems="center">
          <TextField
            size="small"
            fullWidth
            id="message"
            placeholder="Write a Message…"
            name="message"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleEnterKeyPress}
            sx={{ flex: 1, mb: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!isProfileSectionActive && (
                    <ChatBotEmojiPicker
                      setValue={setInput}
                      value={input}
                      onSelect={handleEmojiSelect}
                      
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={handleSendMessage} sx={{ mb: 1 }}>
            <Icon icon={roundSend} width={24} height={24} />
          </IconButton>
        </Stack>
          </Paper>
        </Container>
      </Popover>
    </>
  );
};

export default ChatBot;
