import faker from 'faker';
import { sentenceCase } from 'change-case';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// utils

//
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SearchNotFound from 'src/components/SearchNotFound';
export default function CallNotes({callNotesData}){
    const theme = useTheme();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0)
    const [callNotesArray,setCallNotesArray] = useState([]);

    useEffect(()=>{
      if(callNotesData){
        setCallNotesArray(callNotesData)
      }
      else{
        setCallNotesArray([])
      }
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isUserNotFound = callNotesArray.length === 0;

    return(
        <Card sx={{ pb: 3 }}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{width: 500 }} align='center'>Note</TableCell>
                <TableCell align='center'>Related To</TableCell>
                <TableCell align='center'>Added By</TableCell>
                <TableCell align='center'>Added On</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {callNotesArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell align='center'>{ (row.call_sid)  ? "Call" : "Profile" }</TableCell>
                  <TableCell align='center'>{row.added_by}</TableCell>
                  <TableCell align='center'>{moment.unix(row.timestamp).format('DD-MM-YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound msg="Call Notes"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={callNotesArray.length}
             rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> 
    </Card>
    )
}