import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import roundSend from '@iconify/icons-ic/round-send'
import heartFill from '@iconify/icons-eva/heart-fill'
import shareFill from '@iconify/icons-eva/share-fill'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import messageSquareFill from '@iconify/icons-eva/message-square-fill'
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate'
// material
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// utils
import { fDate } from '../../../../utils/formatTime'
import { fShortenNumber } from '../../../../utils/formatNumber'
// hooks
import useAuth from '../../../../hooks/useAuth'
//
import MyAvatar from '../../../MyAvatar'
import EmojiPicker from '../../../EmojiPicker'
import { MCheckbox } from '../../../@material-extend'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'

// ----------------------------------------------------------------------

Messages.propTypes = {
  post: PropTypes.object,
}

export default function Messages({ id, userData, messageCount,isClient }) {
  const commentInputRef = useRef(null)
  const fileInputRef = useRef(null)
  const [message, setMessage] = useState('')
  const [displayedComments, setDisplayedComments] = useState([])
  const [showAll, setShowAll] = useState(false)
  const stackRef = useRef()
  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const userId = user.user_id
  let initialComments

  const fetchData = async () => {
    try {
      let messagesData=null
      if(isClient){
        messagesData = await axios.post(
          `${REST_API_END_POINT}clients/view-message`,
          { userId: userId, toId: userData.user_id},
        )
        initialComments = messagesData.data.message
        setDisplayedComments(initialComments)
        // const resultNewMsg = await axios.post(
        //   `${REST_API_END_POINT}teams/get-new-msg-count`,
        //   {
        //     userId:userId,
        //     toId: userData['user_id'],
        //   },
        // )
       
        // if (resultNewMsg.data.status === 1) {
        //   messageCount(resultNewMsg.data.message)
        // } else if (resultNewMsg.data.status === 2) {
        //   messageCount('0')
        // }
      }else{
        messagesData = await axios.post(
        `${REST_API_END_POINT}teams/view-message`,
        { userId: userId, toId: userData['user_id'] },
      )
      initialComments = messagesData.data.message
      setDisplayedComments(initialComments)
      const resultNewMsg = await axios.post(
        `${REST_API_END_POINT}teams/get-new-msg-count`,
        {
          userId:userId,
          toId: userData['user_id'],
        },
      )
      
      if (resultNewMsg.data.status === 1) {
        messageCount(resultNewMsg.data.message)
      } else if (resultNewMsg.data.status === 2) {
        messageCount('0')
      }
    }
    } catch (error) {
      console.log('messages fetch error', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [userId, userData])

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight
  }, [displayedComments])

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  }

  const sendMessage = async () => {
    let sendMessage=null
    if(isClient){
      sendMessage = await axios.post(
        `${REST_API_END_POINT}clients/send-message`,
        { userId: userId, to: userData.user_id, msg: message },
      )
    }else{
     sendMessage = await axios.post(
      `${REST_API_END_POINT}teams/send-message`,
      { userId: userId, to: userData['user_id'], msg: message },
    )
    }
    if (sendMessage.data) {
      setMessage('')
    }
    await fetchData()
  }

  const handleClickAttach = () => {
    fileInputRef.current.click()
  }

  const handleClickComment = () => {
    commentInputRef.current.focus()
  }

  const handleLoadMore = () => {
    setDisplayedComments(predefinedComments)
    setShowAll(true)
  }

  const handleLoadLess = () => {
    setDisplayedComments(initialComments)
    setShowAll(false)
  }
  return (
    <Card>
      <Stack spacing={3} sx={{ p: 1 }}>
        <Box />
        <Stack
          ref={stackRef}
          spacing={3}
          sx={{ p: 1 }}
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          {displayedComments.length>0 && displayedComments?.map((comment) => (
            <Stack
              key={comment?.chat_id}
              direction="row"
              spacing={2}
              justifyContent={
                comment.direction === 'left' ? 'flex-start' : 'flex-end'
              }
              alignItems="center"
            >
              {comment?.direction === 'left'
                ? comment.name && (
                    <Avatar alt={comment.name} src={comment.name} />
                  )
                : null}
              <Paper
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  maxWidth: 400, // Adjust this value as needed
                  bgcolor:
                    comment.direction === 'left'
                      ? 'background.neutral'
                      : 'primary.main',
                  color: comment.direction !== 'left' && 'common.white',
                  borderRadius: '12px',
                }}
              >
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems={{ sm: 'center' }}
                  justifyContent="space-between"
                  sx={{ mb: 0.5 }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color:
                        comment.direction === 'left'
                          ? 'text.primary'
                          : 'common.white',
                    }}
                  >
                    {comment?.name}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    {comment?.timestamp}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      comment.direction === 'left'
                        ? 'text.secondary'
                        : 'inherit',
                    maxWidth: '100%',
                    overflowWrap: 'break-word',
                  }}
                >
                  {comment?.message}
                </Typography>
              </Paper>
              {comment?.direction !== 'left'
                ? comment?.name && (
                    <Avatar alt={comment?.name} src={comment?.name} />
                  )
                : null}
            </Stack>
          ))}
        </Stack>

        <Stack direction="row" alignItems="center">
          <MyAvatar />
          <TextField
            fullWidth
            multiline  
            maxRows={4}
            size="small"
            value={message}
            inputRef={commentInputRef}
            placeholder="Write a Message…"
            onChange={handleChangeMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <IconButton size="small" onClick={handleClickAttach}>
                    <Icon
                      icon={roundAddPhotoAlternate}
                      width={24}
                      height={24}
                    />
                  </IconButton> */}
                  <EmojiPicker
                    alignRight
                    value={message}
                    setValue={setMessage}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) =>
                  `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <IconButton onClick={sendMessage}>
            <Icon icon={roundSend} width={24} height={24} />
          </IconButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Stack>
    </Card>
  )
}
