import {
  Container,
  Grid,
  Button,
  Stack,
  Card,
  Box,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  Autocomplete,
  FormControlLabel,
  IconButton,
  Icon,
  Tooltip,Checkbox,Table,TableBody,TableHead,TableCell,TableRow,
  TableContainer,
  TablePagination,
} from '@material-ui/core'
// import { Icon } from '@iconify/react';
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import * as Yup from 'yup'
// import PropTypes from 'prop-types';
import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
//componenets
import { fData } from 'src/utils/formatNumber'
import fakeRequest from 'src/utils/fakeRequest'
import Scrollbar from 'src/components/Scrollbar'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths'
//
import Label from 'src/components/Label'
import { UploadAvatar, UploadSingleFile } from 'src/components/upload'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import SearchNotFound from 'src/components/NoResultProfilePayments'
import TicketsToolbar from '../../user/list/RequestPaymentToolbar'
import { QuillEditor } from 'src/components/editor'
import { ContactSupport } from '@material-ui/icons'
import { UserListHead } from '../../user/list'
import { sentenceCase } from 'change-case'
import { filter } from 'lodash'


//
const TABLE_HEAD = [
  { id: 'ticket', label: 'Tickets #', alignRight: false },
  { id: 'taskname', label: 'Task Name', alignRight: false },
  { id: 'completion', label: 'Completion %', alignRight: false },
  { id: 'taskintotal', label: 'Task In Total', alignRight: false },
  { id: 'completedtask', label: 'Completed Task', alignRight: false},      
  { id: 'pendingtask', label: 'Pending Task', alignRight: false},      
           
];
const STATUS = [
  { id: 0, name: 'New' },
  { id: 1, name: 'In-Progress' },
  { id: 2, name: 'In-Testing' },
  { id: 3, name: 'Completed' },
  { id: 4, name: 'Cancelled' },
]
const PROJECT = [
  { id: 0, name: 'Zeta' },
  { id: 1, name: 'Lafferty' },
  { id: 2, name: 'Rubiks-Built' },
  { id: 3, name: 'Element 79' },
]
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//----------------------------------------------------------
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query,status) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  if(status){
   return filter(array,(user)=> user.status === status)
  }
  return stabilizedThis?.map((el) => el[0]);
}
export default function ContactTickets({tickets,id}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [add, setAdd] = useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [buttonStatus,setButtonStatus] = useState(null)
  const [orderBy, setOrderBy] = useState('ticket');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions

  console.log('tickets----',tickets);

  const isEdit = false
  const NewUserSchema = Yup.object().shape({
    ticket: Yup.string().required('Ticket Name is required'),
    project: Yup.string().required('Job is required'),
    
  })
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }))
  //

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };



  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tickets?.length) : 0;

  const filteredUsers = applySortFilter(tickets, getComparator(order, orderBy), filterName,buttonStatus);

  const isUserNotFound = filteredUsers?.length === 0;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await fakeRequest(500)
        resetForm()
        setSubmitting(false)
        enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', {
          variant: 'success',
        })
        navigate(PATH_DASHBOARD.client.list)
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik

  return (
    <Container>
      {!add ? (
        <>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            marginTop={'-50px'}
            marginBottom={'20px'}
          >
            <Grid item>
              <Button onClick={()=>setButtonStatus(null)} variant="outlined">All Jobs</Button>
            </Grid>
            <Grid item>
              <Button
               onClick={()=>setButtonStatus(1)}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.info.light,
                  color: theme.palette.info.contrastText,
                }}
              >
                New
              </Button>
            </Grid>

            <Grid item>
              <Button
              onClick={()=>setButtonStatus(2)}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.info.main,
                  color: theme.palette.info.contrastText,
                }}
              >
                In Progress
              </Button>
            </Grid>
            <Grid item>
              <Button
              onClick={()=>setButtonStatus(4)}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.warning.light,
                  color: theme.palette.info.contrastText,
                }}
              >
                In Testing
              </Button>
            </Grid>
            <Grid item>
              <Button
              onClick={()=>setButtonStatus(3)}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.success.light,
                  color: theme.palette.info.contrastText,
                }}
              >
                Completed
              </Button>
            </Grid>
            <Grid item>
              <Button
              onClick={()=>setButtonStatus(5)}
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.info.contrastText,
                }}
              >
                Cancelled
              </Button>
            </Grid>
          </Grid>
          <Button variant="contained" 
          disabled={explodedPermissions?.some(permission => ['Add Ticket'].includes(permission)) ? false : true}
          onClick={()=>{
            navigate(PATH_DASHBOARD.client.addticket,{state:{id:id,tasksource:2}})
          }}
          >
            Add new
          </Button>

     <Card>
          <TicketsToolbar ticket numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tickets?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                    const { ID, complete,description,due_date,name,no_of_completed_tasks,
                      no_of_pending_tasks,no_of_tasks,short_code,start_date,status,ticket_uid, } = row;
                    const isItemSelected = selected.indexOf(ID) !== -1;

                    return (
                      <TableRow
                        hover
                        key={ID}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell align="left">{short_code ? sentenceCase(short_code) : null}</TableCell>
                        <TableCell sx={{cursor:'pointer'}}
                         onClick={()=>navigate(PATH_DASHBOARD.crm.viewticket,{state:{id:ID}})}
                         component="th" scope="row" padding="none">                         
                              {name}                       
                        </TableCell>
                        <TableCell align="left">
                        <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color={complete === 0 ? 'error' : complete === 25 ? 'warning' : complete === 50 ? 'info' : 'success'}
                        >
                         {complete}% Completion
                        </Label> 
                          
                          </TableCell>                       
                        <TableCell align="left">{no_of_tasks}</TableCell>
                        <TableCell align="left">{no_of_completed_tasks}</TableCell>
                        <TableCell align="left">{no_of_pending_tasks}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        </>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={10}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Ticket Name"
                        {...getFieldProps('ticket')}
                        error={Boolean(touched.ticket && errors.ticket)}
                        helperText={touched.ticket && errors.ticket}
                      />
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={PROJECT}
                        getOptionLabel={(state) => state.name}
                        // sx={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Project" />
                        )} // Update the label
                      />
                    </Stack>
                    <div>
                      <LabelStyle>Ticket Overview</LabelStyle>
                      <QuillEditor
                        simple
                        id="product-description"
                        value={values.description}
                        onChange={(val) => setFieldValue('description', val)}
                        error={Boolean(
                          touched.description && errors.description,
                        )}
                      />
                    </div>

                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <Box
                        component={MobileDatePicker}
                        label="Start Date"
                        value={values.start}
                        onChange={(date) => setFieldValue('start', date)}
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                      <Box
                        component={MobileDatePicker}
                        label="Due Date"
                        value={values.end}
                        onChange={(date) => setFieldValue('end', date)}
                        renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth />
                        )}
                      />
                    </Stack>

                    <Stack
                      direction={{ xs: 'column', sm: 'row', md: 'column' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <LabelStyle>Ticket Status</LabelStyle>
                        <Tooltip title="Status">
                          <IconButton
                            onClick={() => setOpen(!open)}
                            sx={{
                              position: 'relative',
                              marginLeft: '2px',                             
                            }}
                          >
                            <ContactSupport />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={STATUS}
                        getOptionLabel={(state) => state.name}
                        // sx={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )} // Update the label
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row', md: 'column' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                        <LabelStyle>Assign Yourself To Job</LabelStyle>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Selecting this option will add you to the Job Team." />

                    </Stack>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setAdd(false)
                        }}
                        loading={isSubmitting}
                      >
                        Add
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        type="submit"
                        onClick={() => {
                          setAdd(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </Container>
  )
}
