import * as Yup from 'yup'
import { Avatar, Box, Button, Card, Grid, IconButton, Stack, 
  Tooltip, Typography, TextField, Autocomplete, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import phoneCallFill from '@iconify/icons-eva/phone-call-fill';
import baselineTextsms from '@iconify/icons-ic/baseline-textsms';
import callEnd from '@iconify/icons-ic/call-end';
import HoldCallIcon from '@iconify/icons-ic/pause';
import MuteIcon from '@iconify/icons-ic/mic-off';
import axios from 'axios';
import { async } from 'q';
import { REST_API, REST_API_END_POINT } from '../constants/Defaultvalues';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import LoadingScreen from './LoadingScreen';
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
import { Form, FormikProvider, useFormik } from 'formik'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import { useSnackbar } from 'notistack';
import Modal from '../components/_dashboard/blog/ProjectModel';
import { Device } from '@twilio/voice-sdk';
import ViewLeadProfile from 'src/pages/dashboard/ViewLeadProfile';
// import { Twilio } from 'twilio';


const SOCIALS = [
    {
      name: 'Call',
      icon: <Icon icon={phoneCallFill} width={50} height={50} color="#1C9CEA" />
    },
    {
      name: 'Message',
      icon: <Icon icon={baselineTextsms} width={50} height={50} color="#1C9CEA" />
    }
  ];


function MinimizablePopup() {
  const [contactData,setContactData] = useState([]);
  const [contactId,setContactId] = useState();
  const navigate = useNavigate()
  const [isEdit,setIsEdit] =  useState(false);
  const [view,setView] =  useState(false);
  const [viewLead,setViewLead] =  useState(false);
  const [defaultState,setDefaultState] = useState('');
  const [defaultCountry,setDefaultCountry] = useState('');
  const [statesData,setStatesData] = useState([]);
  const [countryData,setCountryData] = useState([]);
  const [receivedData,setReceivedData] = useState('');
  const [loading,setLoading] = useState(true);
  const [isMessage,setIsMessage] = useState(false);
  const [messageContents,setMessageContents] = useState('');
  const [callStatus,setCallStatus] = useState('');
  const [isMute,setIsMute] = useState(false);
  const [callAccepted,setCallAccepted] = useState(false);
  const [device,setDevice] = useState();
  const [callInstance,setCallInstance] = useState();
  const [clientId,setClientId] = useState();
  const [leadId,setLeadId] = useState();
  const [clientPhoneNumber,setClientPhoneNumber] = useState();
  const [clientName,setClientName] = useState();
  const [clientprofilePic,setClientprofilePic] = useState('avatar');
  const [inCallInterval, setInCallInterval] = useState(null);
  const [inCallStartTime, setInCallStartTime] = useState(0);
  
  const { enqueueSnackbar } = useSnackbar()
  const userSession = JSON.parse(localStorage.getItem('user')); //data from localstorage
  const explodedPermissions =userSession?.exploded_permissions

  const fetchContactData = async (id) => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API_END_POINT}contacts/view-contact`,{id});
      if(response){
        if(response.data.result.contact){
          // console.log("fetch--Contact---Data------------",response.data.result)
          let editData = response.data.result.contact
          let states = response.data.result.states;
          let countries = response.data.result.countries;
          states ? setStatesData(states) : setStatesData([])
          countries ? setCountryData(countries) : setCountryData([])
          editData ? setContactData(editData) : setContactData([])

          if (editData.state_id) {
            const d_state = states.find((d) => d.id === editData.state_id)
            if (d_state) {
              setDefaultState(d_state.id)
            }
            else {
              setDefaultState('')
            }
          }
          if (editData.country) {
            const d_country = countries.find((d) => d.id === parseInt(editData.country))
            if (d_country) {
              setDefaultCountry(d_country.id)
            }
           else {
            setDefaultCountry('')
            }
          }
        }
        else{
          setContactData([])
        }
      }
      else{
        setContactData([])
      }
    } catch (error){
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  }

  useEffect(async() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!');
        // setIsMicAllowed(true);
      })
      .catch(function (err) {
        console.log('No mic for you!');
        enqueueSnackbar('Please connect your microphone', { variant: 'error' });
        //setIsMicAllowed(false);
      });
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get('id');
    const clientId = urlSearchParams.get('clId');
    const lead_id = urlSearchParams.get('lId');
    const phoneNumber = urlSearchParams.get('phone');
    const name = urlSearchParams.get('name');
    setClientPhoneNumber(phoneNumber);
    setClientName(name);
    setClientId(clientId);
    setLeadId(lead_id);
    setContactId(id);
    fetchContactData(id);
  }, [receivedData]);

  useEffect(async()=>{
    let token = ''
    let user = JSON.parse(localStorage.getItem('user'))
    const response = await axios.post(`${REST_API}fetch-worker-token`,{ user: user.email, workerSid: user.worker_sid, },
      { headers: { 'Content-Type': 'application/json', }, });
    console.log(response.data.worker,response.data.clientToken);
    token = response.data.clientToken
    const deviceOptions = {
      logLevel: 1,
      edge: 'ashburn', 
      enableImprovedSignalingErrorPrecision: true,
    }
    const newDevice = new Device(token,deviceOptions);
    setDevice(newDevice)
  },[]);

  useEffect(()=>{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const dial = urlSearchParams.get('dial');
    const phoneNumber = urlSearchParams.get('phone');
      if(device && dial === 'true'){
        console.log('55555555555555555555');
        // handleFunction('Call')
        axios.post(`${REST_API}twilio-calls/scan-crm-phone-number`,{phoneNumber})
        .then((response)=>{
          if (response.data.status === 1){
            console.log("Incoming name : ",response.data.existenceResult);
            const resultData = response.data.existenceResult;
            if(resultData){
              const name = resultData.split(',')[2];
              const profile_pic = resultData.split(',')[3];
              setClientName(name);
              setClientprofilePic(profile_pic);
            }
          }
          else{
            setClientName('Unknown Number');
          }
        })
      }
  },[device]);

  useEffect(() => {
    if (inCallInterval == null && callAccepted) {
      setInCallInterval(setInterval(function () {
        setInCallStartTime(prevTime => prevTime + 1);
      }, 1000));
    }
    return () => {
      if (inCallInterval) {
        clearInterval(inCallInterval);
      }
    };
  }, [inCallInterval, callAccepted]);

  const formatTime = (time) => {
    let finalTime = '';

    const hours = Math.floor(time / 3600);
    if (hours > 0) {
      finalTime += hours > 9 ? hours.toString() : '0' + hours.toString();
      finalTime += ":";
    }

    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    finalTime += minutes > 9 ? minutes.toString() : '0' + minutes.toString();
    finalTime += ":";
    finalTime += seconds > 9 ? seconds.toString() : '0' + seconds.toString();

    return finalTime;
  };

  const handleFunction = async(name) => {
    if(name === 'Call'){
      if(explodedPermissions?.some(permission => ['Calls'].includes(permission))){
        if(device.audio.availableInputDevices.size != 0 || device.audio.availableOutputDevices.size != 0){
            let phoneNumber = (contactData.phone) ? ('+'+contactData.phone) : ('+'+clientPhoneNumber)
            console.log('Phone Number:', phoneNumber);
            
            let call = await device?.connect({ params: { To: phoneNumber } });
            setCallInstance(call);

            console.log("call response",call);
            console.log("device in call - ",device);

            call.on('error', (callError) => {
              console.log("callError",callError);
            })
            
            call.on("accept", (callAccepted) => {
              setCallStatus("IN CALL");
              setCallAccepted(true)
              console.log("call accepted",callAccepted.parameters.CallSid);
            });

            call.on('ringing', (ringing) => {
              setCallStatus(`Calling ${phoneNumber}...`);
              console.log("call ringing",ringing);
            });

            call.on('connect', (connect) => {
              console.log('Outgoing call is successfully connected',connect);
            });

            call.on("disconnect", (disconnect) => {
              hangUpCall();
              console.log("call disconnected",disconnect);
              setCallStatus("")
              setCallAccepted(false)
              setIsMute(false)
              clearInterval(inCallInterval);
              setInCallInterval(null)
              setInCallStartTime(0)
              axios.post(`${REST_API}twilio-calls/add-call-details-ajax`,
              {callSid:disconnect.parameters.CallSid,fromUserId:userSession.user_id})
              .then((response)=>{
                console.log("add-call-details--",response.data);
              })
            });

            call.on("reject", (reject) => {
              console.log("The call was rejected.",reject);
            });

            call.on("ignore", (ignore) => {
              console.log("The call was ignored.",ignore);
            });

            call.on('initiated', () => {
              console.log('initiated');
            });
            call.on('answered', () => {
              console.log('answered');
            });
            call.on('completed', () => {
              console.log('completed');
            });

            call.on("cancel", (callError) => {
              console.log("call cancelled",callError);
            });

            device.on('connect', (connect) => {
              console.log("device connected",connect);
            });
            device.on('initiated', (registered) => {
              console.log("initiated",registered);
            });
            device.on('answered', (registered) => {
              console.log("answered",registered);
            });
            device.on('ringing', (registered) => {
              console.log("ringingggg",registered);
            });
            device.on('completed', (registered) => {
              console.log("completed",registered);
            });


            device.on('registered', (device) => {
              console.log("Twilio.Device is registered!",device);
            });
            device.on('error', (deviceError) => {
              console.log("Twilio.Device Error: ", deviceError);
            });
            device.on('unregistered', (deviceError) => {
              console.log("unregistered: ", deviceError);
            });
            device.on('registering', (device) => {
              console.log("Twilio.Device is registering!", device);
            });
            device.on('destroyed', (connection) => {
              console.log("device destroyed:", connection);
            });
            device.audio.outgoing(true)
        }
        else{
          console.log('No mic for you!');
          enqueueSnackbar('Please connect your microphone', { variant: 'error' });
        }
      }else{
        enqueueSnackbar("Access Denied: You do not have permission to call", { variant: 'error' })
      }
  }else if (name === 'Message'){
      if(explodedPermissions?.some(permission => ['SMS'].includes(permission))){
        setIsMessage(true)
      }else{
        enqueueSnackbar("Access Denied: You do not have permission to send SMS", { variant: 'error' })
      }
    }
  }

  const handleSendMessage = async () => {
    try{
      if(messageContents){
        console.log("messageContents : ",messageContents);
        let phoneNumber = (contactData.phone) ? ('+'+contactData.phone) : ('+'+clientPhoneNumber)
        const response = await axios.post(`${REST_API}initiate-message`,{
          messageContents,
          phoneNumber,
          userId:userSession.user_id,
          ...(contactId && { contact_id: contactId }),
          ...(clientId && {partner_id : clientId }),
          ...(leadId && {lead_id : leadId }),

        })
        console.log("Send message response : ",response.status);
        if(response.status === 200){
          enqueueSnackbar('Message sent successfully', { variant: 'success' });
        }
        else{
          enqueueSnackbar('Error sending message', { variant: 'error' });
        }
        setIsMessage(false);
        setMessageContents();
      }else{
        enqueueSnackbar('Please type a message', { variant: 'error' });
        console.log("No message");
      }
    }
    catch(error){
      enqueueSnackbar('Error sending message', { variant: 'error' });
      console.log(error);
    }
  }

  const hangUpCall = () =>{
    device.disconnectAll();
    device.on('status', function (status) {
      console.log('device status 2 :', status);
    })

    // call.on('status', function (status) {
    //   console.log('Call status 2 :', status);
    // })
    device.on('disconnect', (connection) => {
      console.log("device disconnect:", connection);
    });

    device.on('disconnected', (connection) => {
      console.log("device disconnected:", connection);
    });

    device.on('destroyed', (connection) => {
      console.log("device destroyed:", connection);
    });
    device.on('unregistered', (deviceError) => {
      console.log("unregistered: ", deviceError);
    });
    device.on('error', (deviceError) => {
      console.log("Twilio.Device Error: ", deviceError);
    });

    console.log("device in hangup- ",device);
    setCallStatus('')
  }

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.number().typeError('Please enter a valid phone number').required('Phone number is required'),
    state: Yup.string().required('Please select the State'),
    country: Yup.string().required('Please select the Country'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: contactData ? contactData.name : "",
      email: contactData ? contactData.email_id : "",
      phone: contactData ? contactData.phone : "",
      address: contactData ? contactData.address : "",
      city: contactData ? contactData.city : "",
      state: defaultState !== undefined ? defaultState : '',
      country: defaultCountry !== undefined ? defaultCountry : '',
      code: contactData ? contactData.zip_code : "",
      description: contactData ? contactData.description : "",
    },
    validationSchema: NewUserSchema,
    onSubmit: async ( values, {setSubmitting, resetForm, setErrors, setFieldError }) => {
      try {
        setIsEdit(false)
        const editResponse = await axios.post(`${REST_API_END_POINT}contacts/edit-contact`,{values,userId:userSession.user_id,editId:contactId})
        if(editResponse.data.status === 1){
          setReceivedData(editResponse);
          setIsEdit(false)
          enqueueSnackbar(editResponse.data.message, { variant: 'success' })
          resetForm()
        }
        else if((editResponse.data.status === 0) && (editResponse.data.status === 2)){
          enqueueSnackbar(editResponse.data.message, { variant: 'error' })
        }
        else {
          enqueueSnackbar("Server Error", { variant: 'error' })
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })

  const {errors,values,touched,handleSubmit,isSubmitting,setFieldValue,getFieldProps,} = formik


  const handleCancel = (formik) => {
    setIsEdit(false);
    formik.resetForm();
  };

  return (
    loading ? (
      <LoadingScreen />
    ):
      (isEdit ? (
        <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <Card sx={{ p: 3 }}>
                  <Typography variant="h4" align='center' marginBottom={0.5}>Edit Contact</Typography>
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Phone"
                      {...getFieldProps('phone')}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                    <TextField
                      fullWidth
                      label="City"
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Stack>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={9}
                    label="Address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                        <Autocomplete
                        select
                        fullWidth
                        id="state"
                        options={statesData}
                        getOptionLabel={(option) => option.name}
                        // defaultValue={defaultState}
                        // defaultValue={statesData.find((option) => option.id === defaultState) || null}
                        // value={values.state}
                        value = {statesData.find((option) => option.id === values.state) || null}
                        onChange={(event, newValue) =>{
                          if(newValue){
                            const selectedOption = statesData.find(option => option.id === newValue.id)
                            if(selectedOption){
                              setFieldValue('state', newValue.id);
                            }else {
                              setFieldValue('state', '');
                            }
                          }
                          else {
                            setFieldValue('state', '')
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            placeholder="Select State"
                            fullWidth
                            SelectProps={{ native: true }}
                            error={Boolean(touched.state && errors.state)}
                            helperText={touched.state && errors.state}
                          />
                        )}
                      />
                      <Autocomplete
                        select
                        fullWidth
                        id="country"
                        options={countryData}
                        getOptionLabel={(option) => option.name}
                        // defaultValue={defaultCountry}
                        // defaultValue={countryData.find((option) => option.id === defaultCountry) || null}
                        value={countryData.find((option) => option.id === values.country) || null}
                        onChange={(event, newValue) =>{
                          if(newValue){
                            const selectedOption = countryData.find(option => option.id === newValue.id)
                            if(selectedOption){
                              setFieldValue('country', newValue.id)
                            }
                            else {
                              setFieldValue('country', '');
                            }
                          }
                          else{
                            setFieldValue('country', '')
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            placeholder="Select Country"
                            fullWidth
                            SelectProps={{ native: true }}
                            error={Boolean(touched.country && errors.country)}
                            helperText={touched.country && errors.country}
                          />
                        )}
                      />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                  <TextField
                      fullWidth
                      label="Zip/Postal code"
                      {...getFieldProps('code')}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={9}
                    label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <Box sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: 1,
                    }}
                  >
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Save
                    </LoadingButton>
                    <Button variant="outlined" onClick={() => {handleCancel(formik)}}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
        </>
      )
      :
      <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={8}>
          <Box 
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt:2,
              }}
          >
        <Card sx={{ px: 3, py: 10, height:'90vh', width:'80vw' }}>
        <Stack>
          <Box>
            {/* <MyAvatar
              sx={{
                mx: 'auto',
                width: { xs: 120, md: 192 },
                height: { xs: 120, md: 192 }
              }}
            /> */}
            <Avatar
              sx={{
                  mx: 'auto',
                  width: { xs: 90, md: 144 },
                  height: { xs: 90, md: 144 }
              }} 
              // alt={clientName} 
              src={clientprofilePic+'.jpg'} 
              // src={''} 
              />
          </Box>
          <Box
            sx={{
              ml: { md: 3 },
              mt: { xs: 3, },
              color: 'common.white',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Typography variant="h4">{contactData.name || clientName}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72, mt : 0.5 }}>+{contactData.phone||clientPhoneNumber}</Typography>
            {/* <Typography sx={{ opacity: 0.72 }}>{'Traffic Solutions'}</Typography> */}
            <Typography variant="subtitle2" sx={{mt : 3 }}>{callStatus}</Typography>
            {callAccepted && <Typography variant="body" sx={{mt : 3 }}>{formatTime(inCallStartTime)}</Typography>}
          </Box>
          {(callStatus || isMessage || clientPhoneNumber) ? null :
          <Stack direction={'row'} marginTop={5} spacing={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box
              sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
          >
              <Button variant="outlined" onClick={()=>{setView(true)}}>View Profile</Button>
          </Box>
          <Box
              sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              }}
          >
              <Button variant="outlined" onClick={()=>{setIsEdit(true)}}>Edit Profile</Button>
          </Box>
          </Stack>}
          {leadId &&
            <Stack direction={'row'} marginTop={5} spacing={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button variant="outlined" onClick={()=>{setViewLead(true)}}>View Profile</Button>
              </Box>
            </Stack>
          }
          {(callStatus || isMessage) ? null : <Box sx={{ textAlign: 'center', mt: 5, mb: 2.5 }}>
              {SOCIALS?.map((social) => (
              <Tooltip key={social.name} title={social.name}>
                  <IconButton
                  onClick={()=>{handleFunction(social.name)}}>{social.icon}</IconButton>
              </Tooltip>
              ))}
          </Box>}
          {isMessage && 
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <Box sx={{mt:3}}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={9}
                  label="Message"
                  name="msg"
                  onChange={(event)=>{setMessageContents(event.target.value)}}
                />
                <Stack direction={'row'} marginTop={5} spacing={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Button variant="contained" onClick={()=>handleSendMessage()}>Send Message</Button>
                  <Button variant="outlined" onClick={()=>{setIsMessage(false);setMessageContents('')}}>Cancel</Button>
                </Stack>
              </Box>
            </Stack>
          }
        </Stack>
        {callStatus && 
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!callAccepted ?
            <IconButton onClick={()=>hangUpCall()} sx={{backgroundColor:"#ffffff", marginTop:5}}>
              <Icon icon={callEnd} size={50} color='#ff0000'/>
            </IconButton>
          :
          <>
            <IconButton onClick={() => {
                callInstance.mute(!isMute)
                setIsMute(!isMute)
              }}
            sx={{backgroundColor: isMute  ? '#4287f5' : '#aeb1b5', marginTop:5, marginRight:3}}>
              <Icon icon={MuteIcon} size={50} color='#ffffff'/>
            </IconButton>
            <IconButton onClick={()=>hangUpCall()} sx={{backgroundColor:"#ffffff", marginTop:5}}>
              <Icon icon={callEnd} size={50} color='#ff0000'/>
            </IconButton>
          </>
          }
        </div>
        }
        </Card>
        </Box>
        </Grid>
        </Grid>

      <Modal
      open={view}
      handleClose={() => {
        setView(false);
      }}
      modalTitle={'View Contact Profile'}
      >
      <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              spacing={3}
            >
              {/* {console.log("contactData",contactData)} */}
              <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.name ? 'inherit' : 'gray' }}>
                    Name: {contactData.name}
                  </span>
                </Typography>
                </Stack>
                <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.email_id ? 'inherit' : 'gray' }}>
                    Email: {contactData.email_id}
                  </span>
                </Typography>
              </Stack>
              <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.phone ? 'inherit' : 'gray' }}>
                    Phone: {contactData.phone}
                  </span>
                </Typography>
                </Stack>
                <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.address ? 'inherit' : 'gray' }}>
                    Address: {contactData.address}
                  </span>
                </Typography>
              </Stack>
              <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.city ? 'inherit' : 'gray' }}>
                    City: {contactData.city}
                  </span>
                </Typography>
                </Stack>
                <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.state_name ? 'inherit' : 'gray' }}>
                    State: {contactData.state_name}
                  </span>
                </Typography>
              </Stack>
              <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.zip_code ? 'inherit' : 'gray' }}>
                    Zip/Postal Code: {contactData.zip_code}
                  </span>
                </Typography>
                </Stack>
                <Stack direction="row" display="flex" justifyContent="space-between" >
                <Typography variant="subtitle2">
                  <span style={{ color: contactData.country_name ? 'inherit' : 'gray' }}>
                    Country: {contactData.country_name}
                  </span>
                </Typography>
              </Stack>
              <Stack display="flex" justifyContent="center">
                <span style={{ color: contactData.description ? 'inherit' : 'gray' }}>
                  Description : {contactData.description}
                </span>
              </Stack>
              </Stack>
          </Card>
        </Grid>
      </Grid>
      </Modal>
      <Modal
      open={viewLead}
      handleClose={() => {
        setViewLead(false);
      }}
      modalTitle={'View Lead Profile'}
      >
      <ViewLeadProfile id={leadId} />
      </Modal>
      </>)
      )
}

export default MinimizablePopup;

