//materail

import { Box, Stack,Paper,Card,Typography, Grid } from "@material-ui/core"
import { useEffect, useRef } from "react"
import Scrollbar from "src/components/Scrollbar"
import { fData } from "src/utils/formatNumber"
import { fNumber } from "src/utils/formatNumber"
import { fDate } from "src/utils/formatTime"
export default function ChatbotMessageList({displayedComments}){
  // console.log('displayed comments',displayedComments)
  const scrollRef = useRef();
  console.log({firstChat});
  
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [displayedComments.length]);

  const filteredMessage = displayedComments.filter(
    (msg) =>
      (msg.role == 'user' || msg.role == 'assistant') && msg.content !== null,
  )

  const checkImageContent = (comment) => {
    const urlRegex = /\(([^)]+?)\)/g;
    const matches = comment.content.match(urlRegex);
    const imageUrl = matches ? matches.map(url => url.replace(/\(|\)/g, '')) : null;
  
    return (
      <Paper
        sx={{
          flexGrow: 0.5,
          m: 0.5,
          maxWidth: 200,
          bgcolor: comment.role === 'user' ? 'primary.main' : 'background.neutral',
          color: comment.role === 'user' && 'text.secondary',
          borderTopRightRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: comment.role === 'user' ? 'common.white' : 'inherit',
            maxWidth: '100%',
            whiteSpace: 'normal',
          }}
        >
          {imageUrl ? comment.content.replace(urlRegex, '') : comment.content}
        </Typography>
  
        {imageUrl && imageUrl.map((url, imgIndex) => (
          <img
            key={imgIndex}
            src={url}
            alt={`Image ${imgIndex + 1}`}
            style={{ maxWidth: '100%', maxHeight: '200px', marginBottom: '10px' }}
          />
        ))}
      </Paper>
    );
  };
    return(
        <Grid sx={{ mt: 4, }} xs={12} md={12}>
          <Stack spacing={3} sx={{  mt: 3,minHeight:'400px' }}>
          
        <Scrollbar scrollableNodeProps={{ ref: scrollRef }} >
        
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              color: 'text.disabled',
              textTransform: 'uppercase',
              display: 'flex', 
              alignItems: 'center',ml:'50%' 
            }}
          >
            Today
          </Typography>
          
        
            {filteredMessage.map((comment, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                justifyContent={comment.role == 'assistant' ? 'flex-start' : 'flex-end'}
                // alignItems="center"
              >
                {comment.role == 'assistant'&&(
                    <Box>
                       <img  alt='icon' width='20px' height='20px' src='../Gif/chat-ai.gif'/>
                    </Box>
                   )} 
                  {comment.content && checkImageContent(comment)}
              </Stack>
            ))}
        </Scrollbar>
          </Stack>
      </Grid>
      
    )
}