import { alpha, Chip, useTheme, } from '@material-ui/core'
import React from 'react'

function TagChip({ color, variant, sx, ...props }) {
    const theme = useTheme()
    return (
        <Chip
            sx={{
                bgcolor: variant === "outlined" ? theme.palette.background.paper : color,
                color: variant === "outlined" ? color : theme.palette.background.paper,
                border: `1px solid ${color}`,
                ...(props.onClick ?
                    {
                        '&:hover': {
                            bgcolor: alpha(color, .8),
                        },
                    } : {}),
                ...sx
            }}
            variant={variant}
            {...props}
        />
    )
}

export default TagChip