import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@material-ui/core'
//
import { Icon } from '@iconify/react'
import roundSend from '@iconify/icons-ic/round-send'
import heartFill from '@iconify/icons-eva/heart-fill'
import shareFill from '@iconify/icons-eva/share-fill'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import messageSquareFill from '@iconify/icons-eva/message-square-fill'
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate'
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
//components
import EmojiPicker from 'src/components/EmojiPicker'

export default function ChatbotInput({
  setOpenChat,
  message,
  setMessage,
  fileInputRef,
  commentInputRef,
  handleChangeMessage,
  handleClickAttach,
  handleSendMessage,
  setDisplayedComments,

}) {
  const theme = useTheme()
  const HandlesendMsg = (value) => {
    setOpenChat(true)
    handleSendMessage(value)
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      HandlesendMsg(event.target.value);
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        fullWidth
        size="small"
        value={message}
        inputRef={commentInputRef}
        placeholder="Write a Message…"
        onChange={handleChangeMessage}
        onKeyDown={handleEnterKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClickAttach}>
                <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
              </IconButton>
              <EmojiPicker alignRight value={message} setValue={setMessage} />
            </InputAdornment>
          ),
        }}
        sx={{
          ml: 2,
          mr: 1,
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
          },
        }}
      />
      <IconButton 
      onClick={(e)=>{
        e.preventDefault()
        HandlesendMsg(message)}
        }>
        <Icon icon={roundSend} width={24} height={24} />
      </IconButton>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
    </Stack>
  )
}
