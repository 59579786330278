import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
// import axios from '../utils/axios';
import axios from 'axios';
import { isValidToken, setSession } from '../utils/jwt';
import { REST_API_END_POINT,REST_API } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD, ROOTS_PCB } from 'src/routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
       
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const user = JSON.parse(localStorage.getItem('user'))
          if(user.user_type!=1&&user.user_type!=3){
          const response = await axios.post(REST_API+'user/check-active-user',{userId:user.user_id});
            if(response.data.status==1){
              dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: null
              }
            });
            }
            else{
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: false,
                  user
                }
              });
            }
          }
          else{
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (usename, password) => {
    let data ={}
    data.username = usename,
    data.password = password
    const response = await axios.post(REST_API+'login', data);
    // if(response.data.status==1){
      const { accessToken, user } = response.data;  
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', user.user_type);
        localStorage.setItem('user',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user                                      
        }
      });
    // }
  //  else{

  //  }
                                                                                                                
  
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const fetchTimeLogData = async (userId) => {
    try{
      const res = await axios.post(`${REST_API_END_POINT}project-task/fetch-clockedIn-staff-task-timelog`,{userId})
      if(res.data.status===1){
        const staffTimeLogData = res.data.result
        // console.log('staffTimeLogData---------',staffTimeLogData);
        let clockoutArray = []
        for (const staffTimeLog of staffTimeLogData) {
          if(staffTimeLog.end_time === null){
            const timeLogId = staffTimeLog?.id
            const projectId = staffTimeLog?.job_id
            const taskId = staffTimeLog?.task_id
            let res = await axios.post(`${REST_API_END_POINT}project-task/update-staff-task-timelog`,{projectId, userId, timeLogId,taskId})
            if(res.data.status===1){
              console.log('Clock Out success res : ',res.data.message);
              clockoutArray.push(res.data)
            }else{
              console.log('Clock Out fail res : ',res.data);
            }
          }
        }
        if(clockoutArray?.length === staffTimeLogData?.length){
          console.log('Clocked Out all active subtasks successfully');
        }else{
          console.log("Failed to clock out all subtasks");
        }
      }
    }catch(error){
      console.log('Error fetching clock-in : ',error);
    }
  }

  const logout = async () => {
    setSession(null);
    let userData=JSON.parse(window.localStorage.getItem('user'))
    const response = await axios.post(REST_API+'logout', {
     userId:userData.user_id
    });
    fetchTimeLogData(userData?.user_id)
    if(response.data.status==1){
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_type')
      localStorage.removeItem('user')
      dispatch({ type: 'LOGOUT' })
    }
  
  };

  const resetPassword = (email) => {
    const result = axios.post(REST_API+'forgot-password', { //Link to be changed later
      email,
    })
    return result
  }
  const loginWithUserId=async (userType,id) => {
    console.log("$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",id);
   
      let data = {}
        data.id=id,
        data.userType = userType
      const response = await axios.post(REST_API + 'login-using-id', data);
      // if(response.data.status==1){
      const { accessToken, user } = response.data;
      if (accessToken) {
       
        localStorage.setItem('user', JSON.stringify(user))
                 
      }
      // setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
  
  }
  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        loginWithUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
