import { useState } from 'react';
import { Icon } from '@iconify/react';
import roundFullscreen from '@iconify/icons-ic/round-fullscreen';
import roundFullscreenExit from '@iconify/icons-ic/round-fullscreen-exit';
import { useTheme, alpha } from '@material-ui/core/styles';  // Add alpha import
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Button } from '@material-ui/core';

export default function SettingFullscreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <>
    {isMobile ? 
    <Box sx={{ border: `1px solid ${fullscreen ? theme.palette.primary.main : 'rgba(145, 158, 171, 0.32)'}`, display:'flex', justifyContent:'center', alignItems:'center', px:'12px',py:1, borderRadius:1, color:fullscreen ? `${theme.palette.primary.main}` : 'inherit'}}
    onClick={toggleFullScreen}>
      <Icon height={20} width={20} icon={fullscreen ? roundFullscreenExit : roundFullscreen} />
    </Box>
    :
    <Button
      fullWidth
      size="large"
      variant="outlined"
      color={fullscreen ? 'primary' : 'inherit'}
      startIcon={<Icon icon={fullscreen ? roundFullscreenExit : roundFullscreen} />}
      onClick={toggleFullScreen}
      sx={{
        fontSize: 14,
        ...(fullscreen && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        }),
        display: 'flex',
        justifyContent: 'center', // Center the icon on both mobile and non-mobile views
      }}
    >
      {!isMobile && (fullscreen ? 'Exit Fullscreen' : 'Fullscreen')}
    </Button>}
    </>
  );
}
