
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Slide,
    Box,
  } from '@material-ui/core'
  import { useSnackbar } from 'notistack'
  import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
  import { useTheme } from '@material-ui/core/styles';
import { forwardRef } from 'react';

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ConfirmationPopup({
    open,
    handleClose,
    message,
    handleClick,
}){
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    return(
        <Dialog
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        transitionDuration={500}
        sx={{ minWidth: 600 }}
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ width: 400, wordWrap: 'break-word' }}>
              {message}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end !important' }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleClick();
            }}
            sx={{ color: theme.palette.primary.main }}
          >
            <Typography sx={{ color: theme.palette.common.white }} variant="overline">
              OK
            </Typography>
          </Button>
          <Button
            size="small"
            onClick={() => {
              handleClose();
              enqueueSnackbar('Operation was cancelled', { variant: 'error' });
            }}
            sx={{
                background: '#BB2525',
                boxShadow: `0 8px 16px 0 ${alpha('#BB2525', 0.24)}`,
                '&:hover': {
                  background: '#FF8F8F',
                  boxShadow: `0 8px 16px 0 ${alpha('#FF8F8F', 0.24)}`,
                },
              }}
          >
            <Typography sx={{ color: theme.palette.common.white }} variant="overline">
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
}