import * as Yup from 'yup';
import { filter, truncate } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Box,
  CircularProgress
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserList, deleteUser } from 'src/redux/slices/user';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {REST_API, REST_API_END_POINT, UNITS} from 'src/constants/Defaultvalues';
import moment from 'moment';
import MaterialInventoryMenu from 'src/components/_dashboard/user/list/MaterialInventoryMenu';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { stubTrue } from 'lodash';
import InventoryAuditLog from './InventoryAuditLog';

export default function InventoryFinishedProducts(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const editId = parseInt(id);
  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [editFinishedProductId, setEditFinishedProductId] = useState()
  const [editData, setEditData] = useState([])
  const [openEditInventory, setOpenEditInventory] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ProductList, setProductList] = useState([]);
  const [inventoryLogData, setInventoryLogData] = useState([]);
  const receivedData = location.state?.data;
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const explodedPermissions =userData?.exploded_permissions

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-finished-products`,{editId});
      const productListData = response.data.results;
      const LogResponse = await axios.post(`${REST_API_END_POINT}product/fetch-inventory-audit-log`,{editId});
      const LogResponseData = response.data.logData;
      if(productListData){
        for (const data of productListData){
          const unit = UNITS?.filter((item)=>item.id == data.unit)
          data.unit_name = unit[0]?.name
        }
        setProductList(productListData);
      } else{
        setProductList([]);
      }
      if(LogResponseData){
        setInventoryLogData(LogResponseData)
      } else{
        setInventoryLogData([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [receivedData, refreshData]);

  const fetchDataById = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-finished-product-by-id`,{editFinishedProductId});
      if(response.data.status === 1) {
        setEditData(response.data.results[0])
      }
      else{
        setEditData([])
      }
    }catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    if(editFinishedProductId){
      fetchDataById()
    }
  },[editFinishedProductId])

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query, source) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.barcode.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    if (source) {
      if (source === 'Inventory') {
        return filter(array, (_user) => _user.source === 0);
      }
      else if (source === 'Vendor') {
        return filter(array, (_user) => _user.source === 1);
      }
    }
    return stabilizedThis.map((el) => el[0]);
  }
  
  const NewCommunitySchema = Yup.object().shape({
    qty: Yup.number().typeError('Please enter a valid qty').required('Quantity is required'),
    inventoryReason: Yup.string().required('Please enter the reason'),
  });
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      unitCost: (editFinishedProductId && editData) ? editData.unit_cost : '',
      qty:(editFinishedProductId && editData) ? editData.qty : '',
      total:(editFinishedProductId && editData) ? editData.total_cost : '',
      inventoryReason:'',
    },
    validationSchema: NewCommunitySchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
      try {
        const materialName = editData?.product_name
        const editBarcode = editData?.barcode
        const response = await axios.post(`${REST_API_END_POINT}product/update-inventory`, {values,userId,editFinishedProductId,editId,materialName,editBarcode})
        console.log("Edit inventory response : ", response.data);
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          resetForm();
          setSubmitting(false);
          setOpenEditInventory(false)
          setEditData([])
          setEditFinishedProductId()
          setRefreshData(!refreshData)
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } catch (error) {
        console.error("Error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData?.map((n) => n.id);
      const newSelectedNames = slicedData?.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected?.indexOf(id);
    const selectedNameIndex = selectedNames?.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
      newselectedNames = newselectedNames?.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, selectedNameIndex), selectedNames?.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleFilterBySource = (event) => {
    setFilterSource(event);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  // ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'barcode', label: 'Barcode', alignRight: false },
//   { id: 'name', label: 'Name', alignRight: false },
  { id: 'unit_cost', label: `${ProductList[0]?.unit_name} Cost`, alignRight: false },
  { id: 'qty', label: `Total ${ProductList[0]?.unit_name}`, alignRight: false },
  // { id: 'total_cost', label: 'Total Cost', alignRight: false },
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'vendor', label: 'Vendor', alignRight: false },
  // { id: 'authorized_by', label: 'Added By', alignRight: false },
  // { id: 'timestamp', label: 'Added On', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id:'options', label: '', alignRight: false},      
];

// ----------------------------------------------------------------------

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ProductList?.length) : 0;
  const filteredUsers = applySortFilter(ProductList, getComparator(order, orderBy), filterName, filterSource);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <>
      <Container>
        <Card>
          <MaterialInventoryToolbar 
          numSelected={selected?.length} 
          filterName={filterName} 
          onFilterName={handleFilterByName}
          filterSource={filterSource}
          onFilterSource={handleFilterBySource}
          selectedName={selectedNames} 
          selected={selected} 
          onDelete={() => {setSelected([]);setPage(0);}}
          item="barcode"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {loading ? (
                  // <CircularProgress color="primary" variant="indeterminate" />
                  <Stack sx={{alignItems:'center', justifyContent:'center', minWidth: 800}}>
                    <LoadingScreen />
                  </Stack>
                ) : (
                  <>
                  {slicedData?.map((row) => {
                    let { id,	barcode, name, timestamp, status, unit_cost,qty,total_cost,vendor, authorized_by,source } = row;
                    const isWithinLast24Hours = (timestamp) => {
                      const twentyFourHoursAgo = moment().subtract(24, 'hours');
                      const timestampMoment = moment.unix(timestamp); // Convert timestamp to moment object
                      return timestampMoment.isAfter(twentyFourHoursAgo);
                    };
                    const withinLast24Hours = isWithinLast24Hours(timestamp);
                    const isItemSelected = selected?.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                          {withinLast24Hours && <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={ 'info' }
                          >
                            New
                          </Label>}
                        </TableCell>
                        <TableCell align="center">
                          {/* <Barcode value={barcode} width={0.5} height={30} fontSize={10}/> */}
                          <Typography variant="body">
                          {barcode}
                          </Typography>
                        </TableCell>
                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell> */}
                        <TableCell align="center">{unit_cost}</TableCell>
                        <TableCell align="center">{qty}</TableCell>
                        {/* <TableCell align="center">{total_cost}</TableCell> */}
                        <TableCell align="center">{source === 0 ? 'Inventory' : 'Vendor'}</TableCell>
                        <TableCell align="center">{vendor}</TableCell>
                        {/* <TableCell align="center">{authorized_by}</TableCell>
                        <TableCell align="center">{moment.unix(timestamp).format('DD-MM-YYYY')}</TableCell> */}
                        {/* <TableCell align="center">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 1 && 'info') || 'error'}
                          >
                            {sentenceCase((status === 1) ? "Active" : "Inactive")}
                          </Label>
                        </TableCell> */}

                        <TableCell align="center">
                          {(explodedPermissions?.some(permission => ['Edit Inventory'].includes(permission)))
                          ?
                            <MaterialInventoryMenu
                            userName={name}
                            id={id}
                            status={status}
                            fromFinishedProducts
                            fromInventoryAudit
                            onEdit={(id)=>{setEditFinishedProductId(id);setOpenEditInventory(true) }}
                            onRefresh={()=>{setRefreshData(!refreshData)}} 
                            />
                            : null
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </>
                    )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Item"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            // rowsPerPageOptions={[5, 10, 25]}
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <InventoryAuditLog refreshData={refreshData}/>
      </Container>
      <Modal
        modalTitle={'Edit Inventory'}
        open={openEditInventory}
        handleClose={() => {setOpenEditInventory(false);}}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                        fullWidth
                        label={ProductList[0]?.unit_name+ ' Cost'}
                        type="number"
                        // disabled
                        // {...getFieldProps('unitCost')}
                        // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                        InputProps={{ readOnly:true }}
                        value={values.unitCost}
                        onChange={(e)=>{
                          setFieldValue('unitCost',e.target.value);
                          setFieldValue('total',e.target.value * values.qty)
                        }}
                        error={Boolean(touched.unitCost && errors.unitCost)}
                        helperText={touched.unitCost && errors.unitCost}
                    />
                    <TextField
                        fullWidth
                        label="Quantity"
                        type="number"
                        // {...getFieldProps('qty')}
                        // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                        value={values.qty}
                        onChange={(e)=>{
                          setFieldValue('qty',e.target.value);
                          setFieldValue('total',values.unitCost * e.target.value)
                        }}
                        error={Boolean(touched.qty && errors.qty)}
                        helperText={touched.qty && errors.qty}
                    />
                   <div style={{ display: 'none' }}> <TextField
                        fullWidth
                        label="Total"
                        type="number"
                        {...getFieldProps('total')}
                        error={Boolean(touched.total && errors.total)}
                        helperText={touched.total && errors.total}
                        // disabled
                        // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                        InputProps={{ readOnly:true }}
                    /></div>
                    <TextField
                        fullWidth
                        label="Reason"
                        {...getFieldProps('inventoryReason')}
                        error={Boolean(touched.inventoryReason && errors.inventoryReason)}
                        helperText={touched.inventoryReason && errors.inventoryReason}
                    />
                  </Stack>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {'Save Changes'}
                    </LoadingButton>
                    <Button variant="outlined" onClick={()=>{setOpenEditInventory(false); formik.resetForm(); setEditData([]); setEditFinishedProductId()}} sx={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
}
