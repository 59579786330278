import Modal from "../_dashboard/blog/ProjectModel";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Edit } from '@material-ui/icons';
import { UploadAvatar, UploadSingleFile } from 'src/components/upload';
import { fData } from 'src/utils/formatNumber';
import { storage } from 'src/firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import axios from 'axios';
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import moment from 'moment'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { Box, Card, Grid, Stack,Typography,Button, TextField, FormControl, FormLabel, Select, MenuItem, Autocomplete, RadioGroup, FormControlLabel, Radio, Container } from "@material-ui/core";
import { LoadingButton, MobileDatePicker } from "@material-ui/lab";
import { useCallback, useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useParams } from "react-router";
import HeaderBreadcrumbs from "../HeaderBreadcrumbs";
import LoadingScreen from "../LoadingScreen";

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }));
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))

export default function ViewReportForApp (){
    const [projectView, setProjectView] = useState(null)
    const [loading, setLoading] = useState(true)
    const [templateData,setTemplateData] = useState([])
    const [published,setPublished] = useState(false)
    const [publishedData,setPublishedData] = useState({})
    const label = 'view_template'
    const { id,userType,userId } = useParams(); 

    const fetchTemplateData=async()=>{
      try {
        if(projectView){
            axios.post(`${REST_API_END_POINT}installation/get-template-data`,{id:projectView?.installation_temp_id,inst_id:projectView?.id})
            .then((res)=>{    
            setTemplateData(res.data?.data)
            let  foundData = res.data.data.find((dat) => dat.status === 1);
            setPublishedData(foundData)
                console.log(foundData,'-----------foun data')
                setPublished(foundData !=undefined)
            })
            .catch((error)=>{
            console.log(error)
            })
        }
      } catch (error) {
        console.log(error)
      }finally{
        setTimeout(() => {
            setLoading(false)
        }, 1000);
      }
    }
  
    const handleFetchAppointmentById = async () => {
      try {
        const response = await axios.post(`${REST_API_END_POINT}installation/fetch-apointment-by-id`,{ userType:userType, userId:userId, requestId:id },{
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.data.status === 1) {
          console.log(response.data,'------------result data ')
          let projectData = response.data.result
          setProjectView(projectData[0]);
        }
      }
      catch (error) {
        console.log("Error fetching data:", error);
      }
    }

   useEffect(()=>{
    fetchTemplateData()
   },[projectView])
  
   useEffect(()=>{
      handleFetchAppointmentById()
     },[])
    const NewUserSchema = Yup.object().shape({
        
      })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: templateData?.reduce((acc, curr) => {
            acc[curr.content] = curr.value;
            return acc;
        }, {}),

        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
         try {
            console.log('Update');
         } catch (error) {
            console.log('error',error);
         }
        }
      })
      const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
      console.log("values----",values);
      const handleDropFile = useCallback(
        async (acceptedFiles, field_id, content) => {
          try {
            if (acceptedFiles && acceptedFiles.length > 0) {
              const file = acceptedFiles[0]
              const filename = new Date().getTime() + file.name
    
              const storageRef = ref(storage, filename)
              const uploadTask = uploadBytesResumable(storageRef, file)
    
              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  const newProgress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                  )
                },
                (error) => {
                  console.error('Error during upload:', error)
                },
                async () => {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
                 
                  setFieldValue(content,downloadURL)
                },
              )
            }
          } catch (error) {
            console.error('Unexpected error:', error)
          }
        },
        [setFieldValue],
      )
      const parseContent = (htmlContent) => {
        const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
        return doc.body.textContent || "";
      };
      const rightContentStyle = { color: '#1ccaff' };
    return(
        <Container>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{mt:3}}>
            <HeaderBreadcrumbs heading="View Report" />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                {loading ? 
                    <Stack justifyContent={'center'} alignItems={'center'}>
                        <LoadingScreen />
                    </Stack>
                    :
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            {label ==='view_template' && templateData?.map((data,index)=>{
                                const {name,content,field_id,installation_id,value} = data
                                console.log('name--------------',name);
                                switch (name) {
                                    case 'header':
                                    return (
                                        <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        spacing={3}
                                        marginLeft={2}
                                        key={index}
                                        >
                                        {/* <Typography variant="subtitle2">{sentenceCase(tag)}</Typography> */}
                                        <Typography variant="subtitle2" sx={rightContentStyle}>{content ? sentenceCase(parseContent(content)) : ""}</Typography>
                                        </Stack>
                                    );
                                    case 'paragraph':
                                    case 'textarea':
                                    case 'text':
                                    case 'number':
                                    case 'autocomplete':
                                    case 'select':
                                    case 'checkbox-group':
                                    case 'radio-group':
                                    return (
                                        <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'flex-start'}
                                        spacing={3}
                                        key={index}
                                        // marginLeft={2}
                                        >
                                        <Typography variant="subtitle2">{content ? `${sentenceCase(parseContent(content))} : ` : ""}</Typography>
                                        <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
                                        </Stack>
                                    );
                                    case 'file':
                                    return (
                                        <Stack
                                        direction={'row'}
                                        justifyContent={'flex-start'}
                                        spacing={3}
                                        marginLeft={2}
                                        key={index}
                                        >
                                        <Typography variant="subtitle2">{sentenceCase(parseContent(content))+' : '}</Typography>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            {value && (
                                            <img
                                                src={value}
                                                alt={parseContent(content)}
                                                style={{
                                                width: '120px',
                                                height: '120px',
                                                borderRadius: '5px',
                                                objectFit: 'fill'
                                                }}
                                            />
                                            )}
                                        </Stack>
                                        </Stack>
                                    );
                                
                                    case 'date':
                                    return (
                                        <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'flex-start'}
                                        spacing={1}
                                        key={index}
                                        >
                                        <Typography variant="subtitle2">{sentenceCase(parseContent(content))}</Typography>
                                        <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
                                        </Stack>
                                    );
                                    default:
                                    return null;
                                }
                            })}
                        </Stack>
                    </Card>
                }
                </Grid>
            </Grid>
            </Stack>
        </Container>
    )
}