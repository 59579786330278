import { Icon } from '@iconify/react'
import { capitalCase } from 'change-case'
import { useState, useEffect } from 'react'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Stack,
  Container,
  Grid,
  Card,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,Paper,
} from '@material-ui/core'
//components
import Page from 'src/components/Page'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import Createcampaign from 'src/components/_dashboard/crm/campaign/CreateCampaign'
import AddTarget from 'src/components/_dashboard/crm/targetlist/AddTarget'
import CallLead from 'src/components/_dashboard/crm/lead/CallLead'
import CallNotes from 'src/components/_dashboard/crm/contacts/CallNotes'
// import Sms from 'src/components/_dashboard/crm/contacts/Sms'
import Calls from 'src/components/_dashboard/crm/contacts/Calls'
import Sms from 'src/components/_dashboard/user/profile/Sms.js'
import Whatsapp from 'src/components/_dashboard/crm/contacts/Whatsapp'
import ContactTickets from 'src/components/_dashboard/crm/contacts/ContactTickets'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import axios from 'axios'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { PATH_DASHBOARD } from 'src/routes/paths'

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export default function ViewLeadProfile({id}) {
  const [currentTab, setCurrentTab] = useState('lead information')
  const [leadData,setLeadData] = useState([])
  const [loading, setLoading] = useState(true)
  const [callNotesData,setCallNotesData] = useState([])
  const [twilioCallsData,setTwilioCallsData] = useState([])

  const fetchData = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}leads/view-lead`,{id});
      console.log("View lead result : ",response.data);
      setLeadData(response.data.result.lead[0]);
      setCallNotesData(response.data.result.call_notes);
      setTwilioCallsData(response.data.result.twilio_calls);
    }
    catch(error){
      console.log("view-lead error",error);
    }
    finally{
      setTimeout(function () {
        setLoading(false);
      }, 100);
    }
  }
  useEffect(()=>{
    fetchData();
  },[id])

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
      <Grid item xs={12} md={12}>
        {/* <Card sx={{ p: 3 }}> */}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            spacing={3}
          >
            <Paper
            key={'ddf'}
            sx={{
              p: 3,
              width: 1,
              bgcolor: 'background.neutral'
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              <span style={{ color: leadData?.name ? 'inherit' : 'grey' }}>
                Name : {leadData?.name} 
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.industry_name ? 'inherit' : 'grey' }}>
                Industry : {leadData?.industry_name}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.title ? 'inherit' : 'grey' }}>
                Brand : {leadData?.title}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.email_id ? 'inherit' : 'grey' }}>
                Email : {leadData?.email_id}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.phone ? 'inherit' : 'grey' }}>
                Phone : +{leadData?.phone}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.is_seed_to_sale_software ? 'inherit' : 'grey' }}>
                Are you currently using a seed to sale software : 
                {(leadData?.is_seed_to_sale_software === 2) ? " No" : " Yes"}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.is_receiving_newsletter ? 'inherit' : 'grey' }}>
                Would you be interested in receiving our newsletter : 
                {(leadData?.is_receiving_newsletter === 2) ? " No" : " Yes"}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.is_marketplace_to_sell ? 'inherit' : 'grey' }}>
                Are you currently using a marketplace to sell you products : 
                {(leadData?.is_marketplace_to_sell === 2) ? " No" : " Yes"}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.marketplace_to_sell ? 'inherit' : 'grey' }}>
                If so which one : {(leadData?.marketplace_to_sell) ? leadData?.marketplace_to_sell : '' }
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.is_card_processing ? 'inherit' : 'grey' }}>
                Do have a need for credit card processing or banking : 
                {(leadData?.is_card_processing === 2) ? " No" : " Yes"}
              </span>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span style={{ color: leadData?.lead_status_name ? 'inherit' : 'grey' }}>
                Lead Status : {leadData?.lead_status_name}
              </span>
            </Typography>
          </Paper>
          </Stack>
        {/* </Card> */}
      </Grid>
    </Grid>
  )
}
