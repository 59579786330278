import { TableBody, TableCell, Typography, IconButton, Table, TableContainer, TableHead, TableRow, TablePagination } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Chip } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import TagChip from './TagChip';
import ConfirmationPopup from 'src/components/confirmationPopup';

function TagTable({ tags,rowsPerPage,page, handleEdit, handleDelete, handlePageChange, handleRowsPerPageChange,totalCount }) {
    const [delOpen, setDeleteOpen] = useState()
    return (<>
        <TableContainer >
            <Table
                padding="normal"
                // size='small'
                sx={{ borderCollapse: 'separate', minWidth: 600, borderSpacing: `0px 10px` }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                        >
                            Name
                        </TableCell>
                        <TableCell
                        >
                            Created By
                        </TableCell>
                        <TableCell
                        >
                            Created On
                        </TableCell>                  
                        <TableCell
                            align='center'
                        >
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tags?.map((tag) =>
                    
                        <TableRow
                            hover
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                        // key={row.name}
                        // selected={isItemSelected}
                        >
                            <TableCell>
                                <Typography variant="body2">
                                    <TagChip
                                        label={tag.name}
                                        color={tag.color}
                                        variant={tag.variant}
                                    />

                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="body2">
                                    {`${tag?.authorized_by}`}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2">
                                    {moment.unix(tag.timestamp).format('MM/DD/YYYY')}
                                </Typography>
                            </TableCell>   

                            <TableCell align='center' >
                                <IconButton size='small' onClick={() => handleEdit(tag?.id)}>
                                    <Edit fontSize='small' />
                                </IconButton>
                                <IconButton size='small' onClick={() => setDeleteOpen(tag?.id)}>
                                    <Delete fontSize='small' />
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage||0}
                page={page||0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </TableContainer>
        <ConfirmationPopup
            message={'You want to delete the tag ?'}
            open={Boolean(delOpen)}
            handleClose={() => setDeleteOpen(false)}
            handleClick={()=>{
                handleDelete(delOpen)
                setDeleteOpen(false)
            }}
            onCancel={() => setDeleteOpen(false)}
        />
    </>
    )
}

export default TagTable