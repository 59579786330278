import React from 'react'
import { useMemo } from 'react'

function useWorkSpaceOption() {
    const workSpaceTitle = useMemo(() => JSON.parse(localStorage.getItem('user'))?.job_title||'Jobs')
    return {
        workSpaceTitle 
    }
}

export default useWorkSpaceOption