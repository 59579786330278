import * as Yup from 'yup'
// import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { sentenceCase } from 'change-case'
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Container,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Autocomplete,
  Checkbox, FormControl, FormLabel, RadioGroup, Radio, IconButton, CircularProgress,
} from '@material-ui/core'

// utils
import { fData } from 'src/utils/formatNumber'
import fakeRequest from 'src/utils/fakeRequest'
// routes
//

//component
import Scrollbar from 'src/components/Scrollbar'
import Page from 'src/components/Page'
import Label from 'src/components/Label'
import { UserListHead } from 'src/components/_dashboard/user/list'
import AddEdit from 'src/components/_dashboard/crm/AddEdit'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import { PATH_CALL, PATH_DASHBOARD } from 'src/routes/paths'
import { Close, KeyboardBackspaceOutlined } from '@material-ui/icons'
import SearchNotFound from 'src/components/SearchNotFound'
import moment from 'moment'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import LoadingScreen from 'src/components/LoadingScreen'
import { Link as RouterLink } from 'react-router-dom'
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup'

export default function Crmaddlead() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [loading, setLoading] = useState(true)
  const [industries,setIndustries] = useState([])
  const [leadStatus,setLeadStatus] = useState([])
  const [editData,setEditData] = useState([])
  const [editCallNotes,setEditCallNotes] = useState([])
  const [editCallNotesId,setEditCallNotesId] = useState([])
  const [openDeletePop,setOpenDeletePop] = useState(false)
  const [noteToDelete,setNoteToDelete] = useState()
  
  const userSession = JSON.parse(localStorage.getItem('user'))
  const userId = userSession.user_id;
  const location = useLocation();
  const editId = location.state?.data;
  const addToLeadPhone = location.state?.addToLeadPhone;
// console.log("editId----------------",editId);

  const NewUserSchema = Yup.object().shape({
    leadName: Yup.string().required('Lead Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    lead_status:Yup.string().nullable().required('Lead status is required'),
    phone: Yup.number().typeError('Please enter a valid phone number')
              .required('Phone number is required')
              .test('maxDigits', 'Phone number can have at most 20 digits', value =>value ? value.toString().length <= 20 : true),
    notes: Yup.array().of(Yup.mixed().required('Note is required')),
  })
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }))
  //
const fetchLeadData = async () => {
  setLoading(true)
  try{
    const response = await axios.post(`${REST_API_END_POINT}leads/get-add-lead-data`)
    if(response){
      const lead = response.data
      setIndustries(lead?.industries);
      setLeadStatus(lead?.lead_status);
    }else{
      setIndustries([])
      setLeadStatus([])
    }
  }
  catch(error){
    console.log("fetchLeadData error",error);
  }
  finally{
    setLoading(false)
  }
}

const fetchEditLeadData = async () => {
  setLoading(true);
  try{
    const response = await axios.post(`${REST_API_END_POINT}leads/get-lead-by_id`,{editId})
    if(response){
      const industriesData = response.data?.industries;
      const leadStatusData = response.data?.lead_status;
      const editLeadData = response.data?.lead[0];
      if(response.data.call_notes){
        const call_notes = response.data?.call_notes;
        setEditCallNotes(call_notes?.map((note) => ({ id: note.id, notes: note.notes })));
        setEditCallNotesId(call_notes?.map((noteid) => noteid.id))
      }
      setIndustries(industriesData);
      setLeadStatus(leadStatusData);
      setEditData(editLeadData);
    }
  }
  catch(error){
    console.log("fetchLeadData error",error);
  }
  finally{
    setLoading(false)
  }
}

useEffect(() => {
  const fetchData = async () => {
    await fetchLeadData();

    if (editId) {
      await fetchEditLeadData();
    }
  };

  fetchData();
}, [editId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        leadName : editData && editData.name || '',
        email :  editData && editData.email_id || '',
        phone :  addToLeadPhone ? addToLeadPhone : (editData && editData.phone || ''),
        brand :  editData && editData.title || '',
        industry : editData && editData.industry_id || null,
        constant_changes : editData && editData.constant_changes || '',
        tracking_database : editData && editData.tracking_database || '',
        marketing : editData && editData.marketing || '',
        printing_and_labels : editData && editData.printing_and_labels || '',
        marketplace_to_sell : editData && editData.marketplace_to_sell || '',
        problem_with_industry : editData && editData.problem_with_industry || '',
        lead_status :  editData && editData.lead_status || null,
        is_seed_to_sale_software : editData && (editData.is_seed_to_sale_software !== undefined && 
          editData.is_seed_to_sale_software.toString()) || '2',
        is_receiving_newsletter : editData && (editData.is_receiving_newsletter !== undefined && 
          editData.is_receiving_newsletter.toString()) || '2',
        is_marketplace_to_sell : editData && (editData.is_marketplace_to_sell !== undefined && 
          editData.is_marketplace_to_sell.toString()) || '2',
        is_card_processing : editData && (editData.is_card_processing !== undefined && 
          editData.is_card_processing.toString()) || '2',
        // notes: (editCallNotes?.length > 0) ? editCallNotes.map((note) => ({ id: note.id, note: note.notes })) : {id:'',note:''},
        notes: (editCallNotes?.length > 0) ? editCallNotes.map((note) => ({ id: note.id, note: note.notes })) : [''],


    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (editId){
          if(editCallNotesId){
            // console.log("editCallNotesId",editCallNotesId);
            values.idNote = editCallNotesId
          }
          const editResponse = await axios.post(`${REST_API_END_POINT}leads/edit-lead`,{editId,values,userId});
          if(editResponse.data.status === 1){
            enqueueSnackbar(editResponse.data.notification, { variant: 'success' });
          }
          else {
            enqueueSnackbar(editResponse.data.message, { variant: 'error' });
          }
        }
        else{
          const response = await axios.post(`${REST_API_END_POINT}leads/add-lead`,{values,userId});
          // console.log("add lead data",response.data);
          if(response.data.status === 1){
            enqueueSnackbar(response.data.message, { variant: 'success' });
          }
          else {
            enqueueSnackbar(response.data.message, { variant: 'error' });
          }
        }
        resetForm();
        setSubmitting(false);
        navigate(PATH_DASHBOARD.crm.lead)
      } catch (error) {
        console.log(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, } = formik

  const getFieldPropsForNote = (index) => {
    return {
      value: values.notes[index].note,
      onChange: (e) => handleNoteChange(e, index),
    };
  };

  const handleNoteChange = (event, index) => {
    const newNotes = [...values.notes];
    newNotes[index] = event.target.value;
    setFieldValue('notes', newNotes);
  };

  const handleDeleteConfirmation = async () => {
    setOpenDeletePop(false);
    // console.log("noteToDelete",noteToDelete);
    // Delete the note from the database
    const deleted = await axios.post(`${REST_API_END_POINT}leads/delete-lead-note`,
    {noteId:noteToDelete.id,leadId:editId,userId});
  
    if (deleted) {
      const newNotes = [...values.notes];
      newNotes.splice(noteToDelete.index, 1);
      editCallNotesId.splice(noteToDelete.index, 1);
      setFieldValue('notes', newNotes);
    } else {
      console.error('Failed to delete note from the database');
    }
  };

  const handleRemoveNote = async (note, index) => {
    const newNotes = [...values.notes];
    if (isSavedNote(index)) {
      setOpenDeletePop(true);
      setNoteToDelete({ id: note.id, index });
    } else {
      newNotes.splice(index, 1);
      setFieldValue('notes', newNotes);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const isSavedNote = (index) => {
    return Boolean(editCallNotesId[index]);
  };

  const handleAdd = () => {
    setFieldValue('notes', [...(values.notes || []), '']);
  };

  return (
    <Page title={editId ? "Edit Lead:CRM leads | Sign-Wise Solution" : "Add Lead:CRM leads | Sign-Wise Solution"}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeaderBreadcrumbs heading={editId ? "Edit Lead" : "Add Lead"} />
          <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 2,
            marginBottom: 2,
          }}
        >
           
          <Button
          variant="outlined"
          type="submit"
          onClick={handleGoBack}
          sx={{ alignItems: 'right' }}
          startIcon={
            <Stack>
              <KeyboardBackspaceOutlined/>
            </Stack>
          }
        >
          {addToLeadPhone ? "Back to Call Logs" :  "Back to All Leads" }
        </Button>
          
          {/* <Button
            variant="outlined"
            type="submit"
            component={RouterLink}
            to={PATH_DASHBOARD.crm.lead}
            sx={{ alignItems: 'right' }}
          >
            Back to All Leads
          </Button> */}
        </Box>
        </Stack>
        {loading ? 
            <CircularProgress color="primary" variant="indeterminate" />
              :
              (
                <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={10}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={3}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <TextField
                                fullWidth
                                label="Name"
                                {...getFieldProps('leadName')}
                                error={Boolean(touched.leadName && errors.leadName)}
                                helperText={touched.leadName && errors.leadName}
                                />
                                <TextField
                                fullWidth
                                label="Email"
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                />
                            </Stack>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <TextField
                                fullWidth
                                label="Phone"
                                {...getFieldProps('phone')}
                                type='number'
                                onKeyPress={(event) => {
                                  if (event.key === '-') {
                                    event.preventDefault();
                                  }
                                  if (event.key === '.') {
                                    event.preventDefault();
                                  }
                                }}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                                />
                                <TextField
                                fullWidth
                                label="Brand"
                                {...getFieldProps('brand')}
                                error={Boolean(touched.brand && errors.brand)}
                                helperText={touched.brand && errors.brand}
                                />
                            </Stack>

                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <Stack
                                sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                <LabelStyle>Currently in business for</LabelStyle>

                          <Autocomplete
                            select
                            fullWidth
                            id="industry"
                            options={industries || []}
                            getOptionLabel={(item) => item.name}
                            sx={{ width: 400 }}
                            value = {industries?.find((option) => option.id === values.industry) || null}
                            onChange={(event, newValue) =>{
                              if(newValue){
                                const selectedOption = industries?.find(option => option.id === newValue.id)
                                if(selectedOption){
                                  setFieldValue('industry', newValue.id);
                                }else {
                                  setFieldValue('industry', '');
                                }
                              }
                              else {
                                setFieldValue('industry', '')
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Type to Search Industry"
                                  error={Boolean(touched.industry && errors.industry)}
                                  helperText={touched.industry && errors.industry}
                              />
                            )}
                          />
                            </Stack>
                            </Stack>
                            <LabelStyle>
                                What do you do to keep up with the constant changes to
                                the industry
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={9}
                                label=""
                                {...getFieldProps('constant_changes')}
                                error={Boolean(touched.constant_changes && errors.constant_changes,)}
                                helperText={touched.constant_changes && errors.constant_changes}
                            />
                            <LabelStyle>
                                How do you feel about the new state tracking database
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={9}
                                label=""
                                {...getFieldProps('tracking_database')}
                                error={Boolean(touched.tracking_database && errors.tracking_database,)}
                                helperText={touched.tracking_database && errors.tracking_database}
                            />
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    Are you currently using a seed to sale software
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="is_seed_to_sale_software"
                                    value={values.is_seed_to_sale_software}
                                    onChange={(e) => {
                                        setFieldValue('is_seed_to_sale_software', e.target.value)
                                    }}
                                    name="is_seed_to_sale_software"
                                    error={Boolean(errors.is_seed_to_sale_software)}
                                >
                                    <FormControlLabel
                                    value='1'
                                    control={<Radio />}
                                    label="Yes"
                                    />
                                    <FormControlLabel
                                    value='2'
                                    control={<Radio />}
                                    label="No"
                                    />
                                </RadioGroup>
                                </FormControl>
                                <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    Would you be interested in receiving our newsletter
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="is_receiving_newsletter"
                                    value={values.is_receiving_newsletter}
                                    onChange={(e) => {
                                        setFieldValue('is_receiving_newsletter', e.target.value)
                                    }}
                                    name="is_receiving_newsletter"
                                    error={Boolean(errors.is_receiving_newsletter)}
                                >
                                    <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                    />
                                    <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="No"
                                    />
                                </RadioGroup>
                                </FormControl>
                            </Stack>
                            <LabelStyle>
                                Who currently does your marketing
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={9}
                                label=""
                                {...getFieldProps('marketing')}
                                error={Boolean(
                                touched.marketing && errors.marketing,
                                )}
                                helperText={touched.marketing && errors.marketing}
                            />
                            <LabelStyle>
                                How does your printing and labels
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={9}
                                label=""
                                {...getFieldProps('printing_and_labels')}
                                error={Boolean(
                                touched.printing_and_labels && errors.printing_and_labels,
                                )}
                                helperText={touched.printing_and_labels && errors.printing_and_labels}
                            />
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    Are you currently using a marketplace to sell you products
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="is_marketplace_to_sell"
                                    value={values.is_marketplace_to_sell}
                                    onChange={(e) => {
                                    setFieldValue("is_marketplace_to_sell",e.target.value)
                                    }}
                                    name="is_marketplace_to_sell"
                                    error={Boolean(errors.is_marketplace_to_sell)}
                                >
                                    <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                    />
                                    <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="No"
                                    />
                                </RadioGroup>
                                </FormControl>
                            </Stack>
                            <LabelStyle>
                                If so which one
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={9}
                                label=""
                                {...getFieldProps('marketplace_to_sell')}
                                error={Boolean(
                                touched.marketplace_to_sell && errors.marketplace_to_sell,
                                )}
                                helperText={touched.marketplace_to_sell && errors.marketplace_to_sell}
                            />
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between'
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    Do have a need for credit card processing or banking
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="is_card_processing"
                                    value={values.is_card_processing}
                                    onChange={(e) => {
                                    setFieldValue("is_card_processing",e.target.value)
                                    }}
                                    name="is_card_processing"
                                    error={Boolean(errors.is_card_processing)}
                                >
                                    <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                    />
                                    <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="No"
                                    />
                                </RadioGroup>
                                </FormControl>
                            </Stack>
                            <LabelStyle>
                                What do you see as the main problem with the industry and what would you do to change that
                            </LabelStyle>
                            <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={9}
                                label=""
                                {...getFieldProps('problem_with_industry')}
                                error={Boolean(touched.problem_with_industry && errors.problem_with_industry,)}
                                helperText={touched.problem_with_industry && errors.problem_with_industry}
                            />
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 3, sm: 2 }}
                            >
                                <Stack
                                sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                <LabelStyle>Status</LabelStyle>
                                <Autocomplete
                                    select
                                    fullWidth
                                    id="lead_status"
                                    options={leadStatus || []}
                                    getOptionLabel={(item) => item.name}
                                    sx={{ width: 400 }}
                                    value = {leadStatus?.find((option) => option.id === values.lead_status) || null}
                                    onChange={(event, value) => {
                                    if (value) {
                                        const selectedOption = leadStatus?.find(option => option.id === value.id);
                                        if (selectedOption) {
                                        setFieldValue('lead_status', value.id);
                                        } else {
                                        setFieldValue('lead_status', '');
                                        }
                                    } else {
                                    setFieldValue('lead_status', '');
                                    }
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} placeholder="Type to Search Lead Status"
                                        error={Boolean(touched.lead_status && errors.lead_status)}
                                        helperText={touched.lead_status && errors.lead_status}
                                    />
                                    )}
                                /> 
                                </Stack>
                            </Stack>
                            <LabelStyle>
                                Notes
                            </LabelStyle>
                        {values.notes && values.notes.map((note, index) => (
                                    <Stack key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={2}
                                        maxRows={9}
                                        label=""
                                        {...getFieldPropsForNote(index)}
                                        // value={[...values.notes[index].note]}
                                        // onChange={(e) => {handleNoteChange(e, index)
                                        // console.log("eeeeeeeeeeeeee",e);
                                        // console.log("indexxxxxxxxxx",index);
                                      // }}
                                        error={touched.notes && Boolean(errors.notes?.[index])}
                                        helperText={touched.notes && errors.notes?.[index]}
                                    />
                                    {index > 0 && (
                                        <IconButton onClick={() => handleRemoveNote(note,index)}>
                                        <Close sx={{ width: 18, height: 18, color: theme.palette.info.light }} />
                                        </IconButton>
                                    )}
                                    </Stack>
                                ))}

                            <Box
                                sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'flex-start',

                                }}
                            >
                                <Button variant='outlined' onClick={handleAdd}>
                                Add Another
                                </Button>
                            </Box>

                            <Box
                                sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 1,
                                }}
                            >
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                Save
                                </LoadingButton>
                                <Button variant="outlined" onClick={() => {handleGoBack()}} >
                                Cancel
                                </Button>
                            </Box>
                            </Stack>
                        </Card>
                        </Grid>
                        {/* Confirmation for deleting opportunity */}
                        <ConfirmationPopup
                          open={openDeletePop}
                          handleClose={()=>{setOpenDeletePop(false)}}
                          message={`You want to delete this note? `}
                          handleClick={handleDeleteConfirmation}
                        />
                    </Grid>
                    </Form>
                </FormikProvider>
              )}
      </Container>
    </Page>
  )
}
