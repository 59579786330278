import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import linkedinFill from '@iconify/icons-eva/linkedin-fill'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import instagramFilled from '@iconify/icons-ant-design/instagram-filled'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { sentenceCase } from 'change-case'
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
  Button,
  Paper,
} from '@material-ui/core'
// utils
import { fShortenNumber } from 'src/utils/formatNumber'
//
import SvgIconStyle from 'src/components/SvgIconStyle'
import Label from 'src/components/Label'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Folder, Message, MessageSharp, RequestQuote } from '@material-ui/icons'
import moment from 'moment'
import { getAgoString } from 'src/common/Helper'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import axios from 'axios'
import { COMPANY_LOGO, REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  //    background: `url(${src}) center/cover no-repeat`
})

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography
        variant="caption"
        sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}
      >
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  )
}

ClientProjectCard.propTypes = {
  card: PropTypes.object.isRequired,
}

export default function ClientProjectCard({ card, clientUserId }) {
  const theme = useTheme()
  const clientId = clientUserId
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [openDeletePop,setOpenDeletePop] =useState(false);
  const [deletingId,setDeletingId] = useState();
  const [deletingName,setDeletingName] = useState('');
  const [requestCount,setRequestCount] = useState();
  const [projectCount,setProjectCount] = useState();
  const [viewData,setViewData] = useState(null);

  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const explodedPermissions = user?.exploded_permissions;
  const userId = user.user_id
  const {
    image,
    message,
    timestamp,
    id,
    name,
    status,
    request,
    authorized_by,
    people,
    description,
    subject,
    submission_deadline,
    msg_count,
    proposal_count,
    participation_ends,
    services,
    accept_status,
    thumbnail,
    type,
    email,
    phone,
  } = card
  const fetchData = async () => {
    try{
      const data={userType:3,userId:clientId}
      const res = await axios.post(`${REST_API_END_POINT}request/get-all-request`,data)
      if(res?.data?.data?.data){
        const requestData = res?.data?.data?.data
        const request = requestData?.filter((item)=>item?.community == id)
        setRequestCount(request?.length)
      }else{
        setRequestCount(0)
      }
      const projectResponse = await axios.post(`${REST_API}webservice/rfq/get-job-by-client`,{clientId, userId, userType,id})
      if(projectResponse.data){
        const project = projectResponse.data.data
        setProjectCount(project?.length)
      }
    }
    catch(error){
      console.log("fetchData Error : ",error);
    }
  }

  useEffect(()=>{
    fetchData();
  })

  const updatedAt = moment.unix(timestamp).format('YYYY-MM-DD')
  //status color
  const getStatusColorAndLabel = (status) => {
    switch (status) {
      case 1:
        return { color: 'success', label: 'Status: Active' }
      case 0:
        return { color: 'warning', label: 'Status: Inactive' }
      case 2:
        return { color: 'error', label: 'Status: Deleted' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }

  const changeStatus = async (comId, comStatus) => {
    try {
      let deleteId = comId;
      let status = comStatus;
      let response = await axios.post(`${REST_API_END_POINT}community/delete-community`,{deleteId,status,userId});
    //   console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Server Error...!", { variant: 'error' });
        navigate(PATH_DASHBOARD.community.root, { state: { data: `${response.data.message}+${deleteId}` } });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuClick = async (id, status) => {
    let newStatus;
    (status === 1) ? newStatus = 0 : newStatus = 1
    // console.log("id, newStatus",id, newStatus);
    await changeStatus(id, newStatus);
  }

  const handleDeleteClick = async (id, newStatus) => {
    // console.log("id, newStatus",id, newStatus);
    setDeletingName('');
    setDeletingId();
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
  }

  return (
    <Card
      id={id}
      onClick={() => {
        //    navigate(PATH_DASHBOARD.blog.viewproject)
      }}
    >
      <CardMediaStyle>
        <AvatarGroup
          max={7}
          sx={{ '& .MuiAvatar-root': { width: 62, height: 62, zIndex: 10 } }}
        >
        </AvatarGroup>
        <CoverImgStyle
          alt="cover"
          src={thumbnail?thumbnail:COMPANY_LOGO}
        />
        <Typography sx={{ position: 'absolute', top: 8, left: 10, zIndex: 10 }}>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={getStatusColorAndLabel(status).color}
          >
            {getStatusColorAndLabel(status).label}
          </Label>
        </Typography>
        <Box sx={{ position: 'absolute', top: 8, right: 10, zIndex: 10, borderRadius:50 }}>
        {/* <UserMoreMenu
            onDelete={()=>{if(explodedPermissions?.some(permission => ['Delete Projects'].includes(permission))){
              setOpenDeletePop(true);
              setDeletingId(id);
              setDeletingName(name);
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }}}
            onEdit={()=>{if(explodedPermissions?.some(permission => ['Edit Projects'].includes(permission))){
              navigate(PATH_DASHBOARD.community.create,{state : {data : id}})
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }}}
            onStatusChange={()=>{if(explodedPermissions?.some(permission => ['Activate/Deactivate Projects'].includes(permission))){
              handleMenuClick(id,status)
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }}}
            status={status}
            oppName={name}
            oppId={id}
        /> */}
        </Box>
      </CardMediaStyle>
      {userType ==3 && accept_status ==2&& status !==6 && (
        <Typography
        color={theme.palette.error.light}
        variant="body2"
        align="center"
        // sx={{ color: 'text.secondary' }}
      >
          Sorry, You are not eligible to view the request
           because you have not accepted the request
            on or before the Evaluation Stage
      </Typography>
      
      )}
      <Typography
        variant="subtitle1"
        align="center"
        sx={{
          mt: 6,
          color: theme.palette.primary.main,
          cursor:'pointer'
        }}
        onClick={()=>{setViewData(card)}}
      >
        {sentenceCase(name)}
      </Typography>

      <Typography
        variant="body2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        {description}
      </Typography>
      <Typography
        variant="overline"
        align="center"
        sx={{ color: 'text.secondary' }}
      ></Typography>

    <Grid container justifyContent="center" spacing={2} sx={{ my: 2 }}>
    <Grid item>
      {/* <Button
        sx={{ background: theme.palette.primary.main, boxShadow: 'none' }}
        size="small"
        onClick={() => {navigate(PATH_DASHBOARD.community.rfqCommunity, { state: { id: id, name : name } })}}
        variant="contained"
        startIcon={ // Use the startIcon prop to add an icon to the left
            <Stack>
              <Folder/>
            </Stack>
          }
      >
        RFQ
      </Button> */}
      <Stack sx={{alignItems:'center', justifyContent:'center', cursor:'pointer'}} onClick={() => { navigate(PATH_DASHBOARD.client.rfqclient, { state: { clientId: clientId, id:id, name : name } })}}>
        <Folder sx={{ color: theme.palette.warning.main, boxShadow: 'none' }}/>
        <Typography variant="body2">Official Requests</Typography>
        <Typography variant="body2" sx={{color:theme.palette.primary.main}}>Count : {requestCount}</Typography>
    </Stack>
    </Grid>
    <Grid item>
    <Stack sx={{alignItems:'center', justifyContent:'center', cursor:'pointer'}} onClick={() => {navigate(PATH_DASHBOARD.client.jobclient, { state: { clientId: clientId, id:id, name : name } })}}>
        <Folder sx={{ color: theme.palette.warning.main, boxShadow: 'none' }} />       
        <Typography variant="body2">Jobs</Typography>
        <Typography variant="body2" sx={{color:theme.palette.primary.main}}>Count : {projectCount}</Typography>
    </Stack>
      {/* <Button
        sx={{ background: theme.palette.primary.main, boxShadow: 'none' }}
        size="small"
        onClick={() => {navigate(PATH_DASHBOARD.community.rfqProjects, { state: { id: id, name : name } })}}
        variant="contained"
        startIcon={ // Use the startIcon prop to add an icon to the left
            <Stack>
              <Folder/>
            </Stack>
          }
      >
        Projects
      </Button> */}
    </Grid>
    </Grid>

      <Divider />

      <Typography
        variant="subtitle1"
        align="center"
        sx={{ color: 'text.secondary', mt: 2 }}
      >
        {type}
       {/* Created {getAgoString(timestamp)} by {authorized_by} */}
      </Typography>
      <Grid
        container
        sx={{
          textAlign: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          pb: 2,
          pr: 2,
        }}
      >
        {msg_count && <Typography
          variant="body2"
          align="center"
          sx={{ color: 'text.secondary' }}
        >
          <MessageSharp sx={{ width: 20, height: 20 }} />
          {msg_count}
        </Typography>}
        {proposal_count && <Typography
          variant="body2"
          align="center"
          sx={{ color: 'text.secondary' }}
        >
          <RequestQuote sx={{ width: 20, height: 20 }} />
          {proposal_count}
        </Typography>}
      </Grid>
      <ConfirmationPopup
        open={openDeletePop}
        handleClose={()=>{setOpenDeletePop(false)}}
        message={`You want to delete ${deletingName} ! `}
        handleClick={()=>{handleDeleteClick(deletingId, 2)}}
      />
      <Modal
        modalTitle={'View Project'}
        open={viewData ? true : false}
        handleClose={() => {
          setViewData(null)
        }}
      >
      <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
        <Grid item xs={12} md={12}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              spacing={3}
            >
              <Paper
              key={'ddf'}
              sx={{
                p: 3,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.name ? 'inherit' : 'grey' }}>
                  Name : {viewData?.name} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.description ? 'inherit' : 'grey' }}>
                Description : {viewData?.description} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.type ? 'inherit' : 'grey' }}>
                Project Type : {viewData?.type} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.email ? 'inherit' : 'grey' }}>
                Email : {viewData?.email} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.phone ? 'inherit' : 'grey' }}>
                Contact No : {viewData?.phone} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.address ? 'inherit' : 'grey' }}>
                Address : {viewData?.address} 
                </span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <span style={{ color: viewData?.authorized_by ? 'inherit' : 'grey' }}>
                Created By : {viewData?.authorized_by} 
                </span>
              </Typography>
              <Typography variant="body2" gutterBottom>
              Status {' '}
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={(viewData?.status === 1 && 'success') || 'error'}
              >
                {(viewData?.status === 1) ? " Active" : " Inactive"}
              </Label>
              </Typography>
            </Paper>
            <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 1,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setViewData(null)
                  }}
                >
                  Close
                </Button>
              </Box>
            </Stack>
        </Grid>
      </Grid>
      </Modal>
    </Card>
  )
}
