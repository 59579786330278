import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import searchFill from '@iconify/icons-eva/search-fill';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, TextField, Typography, Autocomplete, InputAdornment } from '@material-ui/core';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

// ----------------------------------------------------------------------

OfficialRequestSearch.propTypes = {
  sx: PropTypes.object
};

export default function  OfficialRequestSearch({
   sx,setSearching,searching
  }) {
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearch = async (event) => {
    setSearching(event.target.value)
  };

  return (
    <RootStyle
      sx={{
        ...(!searchQuery && {
          '& .MuiAutocomplete-noOptions': {
            display: 'none'
          }
        }),
        ...sx
      }}
    >
      <Autocomplete
        open
        size="small"
        disablePortal
        popupIcon={null}
        options={[]}
        onInputChange={handleChangeSearch}
         value={searching}
          noOptionsText={null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"Search..."}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{
                        ml: 1,
                        width: 20,
                        height: 20,
                        color: 'text.disabled'
                      }}
                    />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        )}
       
      />
    </RootStyle>
  );
}
